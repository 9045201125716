import { Cell, ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from './TableUI';
import { useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useResponsiveWidth } from '../../../../hooks/useResponsiveTableWidth';
import useUpdateTableInstance from '../../../../hooks/useUpdateTableInstance';
import { useNavigate } from 'react-router-dom';
import { hasId } from '../../../../utils/typeguards';
import { useTable } from '../../../../context/TableProvider';
import { t } from 'i18next';
import { useColumnsVisibility } from '../../../../hooks/useColumnsVisibility';
import { isEqual } from 'radash';

type DataTableVirtualizedRowsProps<TData, T> = {
  columns: ColumnDef<TData>[];
  data: TData[];
  height?: number;
  borderRowClass?: string;
  updateTableData?: (
    rowIndex: number,
    columnId: string,
    value: T,
    setTableData: React.Dispatch<React.SetStateAction<TData[]>>,
  ) => void;
  dragStart?: (e: React.DragEvent<HTMLTableRowElement>, row: TData) => void;
  dragEnd?: (e: React.DragEvent<HTMLTableRowElement>) => void;
  emptyCellClassName?: string;
  isEmptyCell?: (cell: Cell<TData, unknown>) => boolean;
  exportedData?: TData[] | null;
  updateExportedData?: (data: TData[]) => void;
};

export const DataTableVirtualizedRows = <TData, T>({
  columns,
  data,
  height,
  borderRowClass,
  updateTableData: handleUpdateTableData,
  dragStart,
  dragEnd,
  emptyCellClassName,
  isEmptyCell,
  exportedData,
  updateExportedData,
}: DataTableVirtualizedRowsProps<TData, T>) => {
  const navigate = useNavigate();
  const { pageTitleQueryKey, url } = useTable();

  const [tableData, setTableData] = useState<TData[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const updateTableData = (rowIndex: number, columnId: string, value: T) => {
    if (handleUpdateTableData) {
      handleUpdateTableData(rowIndex, columnId, value, setTableData);
    }
  };

  const { columnVisibility, setColumnVisibility } = useColumnsVisibility<TData>(columns);

  useEffect(() => {
    const hasTransformedDataChanged = tableData && !isEqual(tableData, exportedData);
    hasTransformedDataChanged && updateExportedData?.(tableData);
  }, [tableData, exportedData, updateExportedData]);

  const table = useReactTable<TData>({
    data: handleUpdateTableData ? tableData : data, // For non-editable tables, use the original data otherwise input filter in weekly planner won't work
    columns,
    state: {
      columnVisibility,
    },
    meta: {
      updateTableData,
    },
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
  });

  const { rows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 80,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const tableRef = useRef<HTMLTableElement>(null);
  useResponsiveWidth(tableRef, data);

  useUpdateTableInstance(table);

  const handleOnCellClick = ({ cellId, rowOriginal }: { cellId: string; rowOriginal: TData }) => {
    if (!url || !hasId(rowOriginal)) {
      return;
    }

    const id = rowOriginal.id;

    if (id && !cellId.includes('select')) {
      navigate(`${url}${id}?${pageTitleQueryKey}=${rowOriginal[pageTitleQueryKey as keyof TData]}`);
      return;
    }
  };

  const hasRow = rowVirtualizer.getVirtualItems().length > 0;
  const hasFooter = columns.some((column) => column.footer !== undefined);
  const shouldDisplayFooter = hasRow && hasFooter;

  return (
    <div ref={tableContainerRef} className='relative overflow-auto' style={{ height }}>
      <Table ref={tableRef} className='grid rounded bg-background text-base leading-5'>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className={`flex ${borderRowClass}`}>
              {headerGroup.headers.map((header) => {
                const isSubHeader = header.depth === 2;
                const headerAdditionalClass = isSubHeader
                  ? header.column.columnDef.meta?.subHeaderRowClass
                  : header.column.columnDef.meta?.topHeaderRowClass;

                return (
                  <TableHead
                    key={header.id}
                    className={`flex items-center justify-center bg-background p-2 text-center text-foreground ${headerAdditionalClass} `}
                    style={{
                      width: header.getSize(),
                      height: 'unset',
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody
          className={`[&_tr:last-child]: relative grid${borderRowClass}`}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          {hasRow ? (
            rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <TableRow
                  className={`absolute flex hover:bg-accent ${borderRowClass} bg-background`}
                  data-index={virtualRow.index}
                  ref={(node) => rowVirtualizer.measureElement(node)}
                  key={row.id}
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                  draggable={dragStart ? true : false}
                  onDragStart={(e) => dragStart?.(e, row.original)}
                  onDragEnd={(e) => dragEnd?.(e)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const isEmptyClassName = isEmptyCell?.(cell) ? emptyCellClassName : '';
                    const cellAdditionalClass = cell.column.columnDef.meta?.cellClassName;
                    return (
                      <TableCell
                        className={`flex justify-center border-foreground px-2 ${isEmptyClassName} ${cellAdditionalClass}`}
                        key={cell.id}
                        width={cell.column.getSize()}
                        onClick={() =>
                          handleOnCellClick({
                            cellId: cell.id,
                            rowOriginal: row.original,
                          })
                        }
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow className={`absolute flex ${borderRowClass} bg-background`}>
              <TableCell className='h-14 text-center' width={table.getTotalSize()}>
                {t('noResult')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {shouldDisplayFooter ? (
          <TableFooter className='sticky bottom-0 bg-background'>
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow key={footerGroup.id} className='font-extrabold hover:bg-accent'>
                {footerGroup.headers.map((footer) => {
                  return (
                    <TableCell
                      key={footer.id}
                      className={`${footer.column.columnDef.meta?.cellClassName}`}
                      width={footer.column.getSize()}
                    >
                      {flexRender(footer.column.columnDef.footer, footer.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableFooter>
        ) : null}
      </Table>
    </div>
  );
};
