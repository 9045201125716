import { useCallback } from 'react';
import axios from 'axios';
import { handleApiError } from '../utils/apiErrorHandler';
import { useNavigate } from 'react-router-dom';
import { ContactEntity, ContactSave } from '../models/Contact';
import { createContact } from '../services/contact';
import stringUtils from '../utils/stringUtils';
import useAuthGuard from './useAuthGuard';
import useToast from './useToast';

type UseAddContactParams = {
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
};

const useAddContact = ({ email, firstname, lastname, telephone }: UseAddContactParams) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  return useCallback(
    async (entityId: string) => {
      if (!token || !orgid) return;

      const isContactDataToSave = firstname || lastname || telephone || email;
      if (!isContactDataToSave) return;

      const payload: ContactSave = stringUtils.formatFieldsForPost({
        firstname: firstname ?? email ?? lastname ?? telephone ?? '',
        lastname,
        entityId,
        contactEntity: ContactEntity.CARRIER,
        telephone,
        email,
      });

      try {
        await createContact({ accessToken: token, orgid, payload });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          handleApiError({ error, addToast, history });
        }
      }
    },
    [token, orgid, firstname, lastname, telephone, email, history, addToast],
  );
};

export default useAddContact;
