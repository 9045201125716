import React, { useEffect, useState } from 'react';
import { FormRow } from '../../stories/dune/atoms/FormRow';
import { Input } from '../../stories/dune/atoms/Input';
import { Title } from '../../stories/dune/atoms/Title';
import PopupContainer from '../../stories/dune/organisms/PopupContainer';
import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button } from '../../stories/dune/atoms/Button';
import { ContactEntity, ContactSave, GetContact } from '../../models/Contact';
import Config from '../../Config';
import axios from 'axios';
import stringUtils from '../../utils/stringUtils';
import useToast from '../../hooks/useToast';
import { ToastTypes } from '../../models/ToastTypes';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

interface FormContactProps {
  contactEntity: ContactEntity;
  entityId: string;

  id?: string;
  lastname: string;
  firstname: string;
  telephone: string;
  email: string;
  companyRole: string;

  isPopup?: boolean;
  onClosePopup?: (validate: boolean, contact: GetContact | undefined) => void;
}

const FormContact = React.memo((props: FormContactProps) => {
  const { addToast } = useToast();
  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const history = useNavigate();

  // #region add product

  const [id, setId] = useState<string | undefined>(props.id);
  const [firstname, setFirstname] = useState<string>(props.firstname);
  const [lastname, setLastname] = useState<string>(props.lastname);
  const [telephone, setTelephone] = useState<string>(props.telephone);
  const [email, setEmail] = useState<string>(props.email);
  const [companyRole, setCompanyRole] = useState<string>(props.companyRole);

  interface IFormInputs {
    firstname?: string;
    lastname?: string;
    telephone?: string;
    email?: string;
    companyRole?: string;
  }

  const schema = yup.object().shape({
    firstname: yup.string().required('Le prénom est obligatoire'),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    setValue('firstname', props.firstname);
    setValue('email', props.email);
  }, []);

  function saveContact() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/contact/add';
    if (id !== undefined) baseUrl = orgid + '/contact/' + id + '/edit';

    if (firstname || lastname || telephone || email) {
      const dataToSend: ContactSave = {
        firstname: firstname ?? email ?? lastname ?? telephone ?? '',
        lastname: lastname,
        entityId: props.entityId,
        contactEntity: props.contactEntity,
        telephone: telephone,
        email: email,
        companyRole: companyRole,
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r: any) => {
          addToast(t('common.contact' + (id ? 'Updated' : 'Added')), ToastTypes.success);
          if (props.onClosePopup) {
            const contact: GetContact = {
              ...dataToSend,
              id: r.data.content.uuid,
              isActive: true,
              createdAt: new Date(),
              createdBy: '',
              lastModificationAt: new Date(),
              lastModificationBy: '',
              sites: [],
              carriers: [],
              vehicles: [],
              customers: [],
              buildingSites: [],
            };

            props.onClosePopup(true, contact);
          }
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  // #endregion add product

  const content = (
    <form>
      <div className='form-section'>
        <FormRow>
          <Title label={id !== undefined ? t('common.editContact') : t('common.newContact')} type='title1' />
        </FormRow>
        <FormRow>
          <Input
            {...register('lastname')}
            error={errors?.lastname?.message ?? ''}
            label={t('common.lastname')}
            type='text'
            value={lastname ?? ''}
            placeholder=''
            disabled={false}
            onChange={function (e): void {
              setValue('lastname', e.toString());
              setLastname(e.toString());
            }}
          />
          <Input
            {...register('firstname')}
            error={errors?.firstname?.message ?? ''}
            label={t('common.firstname')}
            type='text'
            value={firstname ?? ''}
            placeholder=''
            disabled={false}
            onChange={function (e): void {
              setValue('firstname', e.toString());
              setFirstname(e.toString());
            }}
          />
          {!props.isPopup && (
            <>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
              <Input
                {...register('companyRole')}
                error={errors?.companyRole?.message ?? ''}
                label={t('common.companyRole')}
                type='text'
                value={companyRole ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('companyRole', e.toString());
                  setCompanyRole(e.toString());
                }}
              />
            </>
          )}
        </FormRow>
        {props.isPopup && (
          <FormRow>
            <Input
              {...register('telephone')}
              error={errors?.telephone?.message ?? ''}
              label={t('common.telephone')}
              type='text'
              value={telephone ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('telephone', e.toString());
                setTelephone(e.toString());
              }}
            />
            <Input
              {...register('email')}
              error={errors?.email?.message ?? ''}
              label={t('common.email')}
              type='email'
              value={email ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('email', e.toString());
                setEmail(e.toString());
              }}
            />
            <Input
              {...register('companyRole')}
              error={errors?.companyRole?.message ?? ''}
              label={t('common.companyRole')}
              type='text'
              value={companyRole ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('companyRole', e.toString());
                setCompanyRole(e.toString());
              }}
            />
          </FormRow>
        )}
        <FormRow align='right'>
          <Button
            label={t('common.cancel')}
            style='white'
            onClick={() => {
              if (props.onClosePopup) props.onClosePopup(false, undefined);
            }}
          />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(saveContact)} />
        </FormRow>
      </div>
    </form>
  );

  return <>{props.isPopup ? <PopupContainer hideBottomBar={true}>{content}</PopupContainer> : <>{content}</>}</>;
});

FormContact.displayName = 'FormContact';
export default FormContact;
