import React, { useEffect } from 'react';
import { setColumns } from './columns';
import { useTable } from '../../../context/TableProvider';
import { GetInvoice, InvoiceStatus, InvoiceType } from '../../../models/Invoice';
import { DataTableVirtualizedRows } from '../../ui/organisms/table/DataTableVirtualizedRows';
import { useViewportSize } from '../../../hooks/useViewportSize';
import useFetchDataOnLoad from '../../../hooks/useFetchDataOnLoad';
import { generateDefaultErrorMessages } from '../../../utils/apiErrorHandler';
import { fetchInvoices } from '../../../services/invoices';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { TablePaymentInvoice } from './types';

type InvoiceToPayProps = {
  customerId: string;
  paymentInvoices: TablePaymentInvoice[];
  setPaymentInvoices: (invoices: TablePaymentInvoice[]) => void;
  totalRemainingAmount: number;
};

const InvoicesToPayTable: React.FC<InvoiceToPayProps> = ({
  customerId,
  paymentInvoices,
  setPaymentInvoices,
  totalRemainingAmount,
}) => {
  const { tableWidth } = useTable();
  const controller = new AbortController();
  const { token, orgid } = useAuthGuard();
  const orgIdString = orgid ?? '';

  const { data, isLoading, errorMessage } = useFetchDataOnLoad<GetInvoice[]>({
    fetchData: () =>
      fetchInvoices({
        accessToken: token ?? '',
        orgid: orgIdString,
        payload: { customerId, invoiceStatus: InvoiceStatus.UPCOMING, invoiceType: InvoiceType.INVOICE },
        controller,
      }),
    errorMessages: generateDefaultErrorMessages(),
    controller,
    dependencies: [customerId],
  });

  useEffect(() => {
    if (data) {
      setPaymentInvoices(transformAPIData(data));
    }
  }, [data]);

  const transformAPIData = (data: GetInvoice[]): TablePaymentInvoice[] => {
    const transformedData = data.map(({ id, invoiceNumber, payments, totalAmountWithTax }) => {
      return {
        id: id.toString(),
        invoiceNumber,
        assignedAmount: 0,
        remainingAmount:
          totalAmountWithTax - payments.reduce((acc, curr) => acc + (curr.InvoicePayment?.amount ?? 0), 0),
        totalAmount: totalAmountWithTax,
        isSelected: false,
      };
    });
    return transformedData;
  };

  const { viewportHeight } = useViewportSize();
  const tableHeight = viewportHeight - 500;

  const setCurrentAmount = (id: string, amount: number) => {
    const oPayment = paymentInvoices.find((invoice) => invoice.id === id);
    if (oPayment) {
      oPayment.assignedAmount = amount;
      oPayment.isSelected = amount > 0;
      setPaymentInvoices([...paymentInvoices.filter((invoice) => invoice.id !== id), oPayment]);
    }
  };

  const setInvoiceSelected = (id: string, checked: boolean) => {
    const oPayment = paymentInvoices.find((invoice) => invoice.id === id);
    if (oPayment) {
      oPayment.isSelected = checked;
      if (checked && totalRemainingAmount > 0) {
        oPayment.assignedAmount =
          oPayment.remainingAmount <= totalRemainingAmount ? oPayment.remainingAmount : totalRemainingAmount;
      } else if (!checked) {
        oPayment.assignedAmount = 0;
      }
      setPaymentInvoices([...paymentInvoices.filter((invoice) => invoice.id !== id), oPayment]);
    }
  };

  return (
    <>
      {isLoading ? (
        <div className='bg-background' style={{ height: tableHeight }}>
          ...Loading
        </div>
      ) : null}
      {errorMessage && !isLoading ? (
        <div className='bg-background' style={{ height: tableHeight }}>
          {errorMessage}
        </div>
      ) : null}
      {paymentInvoices && !isLoading ? (
        <DataTableVirtualizedRows
          data={paymentInvoices.sort((a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber))}
          columns={setColumns({
            tableWidth,
            savedColumns: [],
            setAmount: setCurrentAmount,
            setSelected: setInvoiceSelected,
          })}
          height={tableHeight}
        />
      ) : null}
    </>
  );
};

export default InvoicesToPayTable;
