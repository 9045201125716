import DataTable from 'react-data-table-component';
import './productItems.scss';
import TableStyles, { childTableStyles, parentTableStyles } from '../../styles/tableStyles';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '../../stories/dune/atoms/Icon';
import { SymbolSwitch } from '../../stories/dune/molecules/SymbolSwitch';
import { useEffect, useState } from 'react';
import { Input } from '../../stories/dune/atoms/Input';
import {
  productItemsActions,
  ProductItemsState,
  ProductItemRow,
  loadTransportServices,
  RowParent,
  getProductParentRows,
} from '../../store/productItems-slice';

import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { SelectProduct } from './SelectProduct';
import { SelectTransportMode, SelectTransportModeOption } from './SelectTransportMode';
import PopupContainer from '../../stories/dune/organisms/PopupContainer';
import { TransportMode } from '../../models/Product';
import { SelectVatRate } from './SelectVatRates';
import { TooltipIcon } from '../../stories/dune/molecules/TooltipIcon';
import { Button } from '../../stories/dune/atoms/Button';
import { GenerateInvoicesParameters, InvoiceStatus, InvoiceType } from '../../models/Invoice';
import axios from 'axios';
import Config from '../../Config';
import useToast from '../../hooks/useToast';
import { ToastTypes } from '../../models/ToastTypes';
import moment from 'moment';
import { VatRate } from '../../models/VatRate';
import { throttle } from 'radash';
import { GlobalParameter } from '../../models/GlobalParameter';
import PriceUtils, { currentCurrency } from '../../utils/priceUtils';
import StringUtils from '../../utils/stringUtils';
import { InputLabel } from '../../stories/dune/atoms/InputLabel';
import { setParentProductColumns } from '../pages/Invoices/columns';
import { roundNumber } from '../../utils/number';

interface ProductItemsProps {
  label: string;
  canAdd: boolean;
  canRemove: boolean;
  isLoading: boolean;
  showProductLink?: boolean;
  showMainProduct?: boolean;
  showExternalRef?: boolean;
  showWeightByUnit?: boolean;
  showOrderedQuantity?: boolean;
  canEditOrderedQuantity?: boolean;
  showDeliveredQuantity?: boolean;
  canEditDeliveredQuantity?: boolean;
  showTransportMode?: boolean;
  canEditTransportMode?: boolean;
  showUnitPrice?: boolean;
  showProductPrice?: boolean;
  canEditUnitPrice?: boolean;
  showDiscountValue?: boolean;
  canEditDiscountValue?: boolean;
  showRowExVatPrice?: boolean;
  showRowInclVatPrice?: boolean;
  showVatRate?: boolean;
  canEditVatRate?: boolean;
  showTotal?: boolean;
  showTotalDetails?: boolean;
  hideIncludedTransportMode?: boolean;
  showProductComment?: boolean;
  hideRep?: boolean;
  showInvoicing?: boolean;
  canEditRowProduct?: boolean;
  displayMode?: undefined | 'trips' | 'buildingSites';
  parentPriceLabel?: string;
  parentRows?: RowParent[];
  rowsHaveEditMode?: boolean;
}

export const ProductItems = (props: ProductItemsProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();
  const { addToast } = useToast();
  const history = useNavigate();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const priceUtils = new PriceUtils({
    showTotal: props.showTotal ?? false,
    showOrderedQuantity: props.showOrderedQuantity ?? false,
    showDeliveredQuantity: props.showDeliveredQuantity ?? false,
    hideRep: props.hideRep ?? false,
  });

  const [invoiceManagement, setInvoiceManagement] = useState<boolean>(false);
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setInvoiceManagement(
      (globalParametersSite.find((x) => x.label === 'invoiceManagement')?.value ?? 'false') === 'true',
    );
  }, []);

  const products = useSelector((state: { productItems: ProductItemsState }) => {
    return state.productItems.products;
  });

  const [isOpenConfirmDeleteProduct, setIsOpenConfirmDeleteProduct] = useState(false);
  const [currentProductToDelete, setCurrentProductToDelete] = useState<ProductItemRow>();

  const [discountMode, setDiscountMode] = useState('');

  const [parentRows, setParentRows] = useState<RowParent[]>([]);
  const [expandedParentRowId, setExpandedParentRowId] = useState<string>('');
  const [editingParentRow, setEditingParentRow] = useState<boolean>(false);
  useEffect(() => {
    if (props.displayMode === 'trips' || props.displayMode === 'buildingSites') {
      const loadParentRows = async () => {
        const rows: RowParent[] = (
          await dispatch(getProductParentRows({ products, groupBy: props.displayMode ?? 'trips' }))
        ).payload as RowParent[];
        setParentRows(rows);
      };
      loadParentRows();
    }
  }, [products, props.displayMode]);

  function addLineBreak(str: string) {
    return str.split('\n').map((subStr) => {
      return (
        <>
          {subStr}
          <br />
        </>
      );
    });
  }

  function seeProductDetail(row: ProductItemRow) {
    const url = 'products';
    return `/${url}/${row.productId}/popup`; // todo : popup
  }

  function seeInvoiceDetail(row: ProductItemRow) {
    const url = 'invoices';
    return `/${url}/${row.invoiceId}/update`;
  }

  useEffect(() => {
    return () => {
      dispatch(productItemsActions.reset());
    };
  }, []);

  const columns: any[] = [
    {
      name: '',
      sortable: false,
      left: true,
      cell: (row: any) => (
        <Link className='table-link' to={seeProductDetail(row)}>
          <Icon icon={'eye'} />
        </Link>
      ),
      button: true,
      omit: !(props.showProductLink ?? false),
    },
    {
      name: <div className='body1 body1-header'>{t('common.trip')}</div>,
      selector: (row: ProductItemRow) => row.tripId,
      cell: (row: ProductItemRow) => (
        <div className='column-align-left'>
          {row.trip && (
            <Link className='body1 sub-table-link' to={`/trips/${row.tripId}/update`}>
              <a>
                {row.trip.tripNumber +
                  (row.trip.externalReference ? '-' + row.trip.externalReference : '') +
                  (' - ' + moment(row.trip.tripDate).format('DD/MM/YYYY'))}
              </a>
            </Link>
          )}
        </div>
      ),
      sortable: false,
      button: false,
      right: false,
      id: 'trip',
      omit: props.displayMode !== 'buildingSites',
      grow: 1,
    },
    {
      name: <div className='body1 body1-header'>{t('common.product')}</div>,
      selector: (row: ProductItemRow) => `${row.label} - ${row.desc}`,
      cell: (row: ProductItemRow) => (
        <>
          {(!rowIsEditMode(row) || !props.canEditRowProduct) && (
            <div className='column-align-left'>
              <div>
                <strong className='body1'>
                  {row.label + (props.showExternalRef ? ' - ' + row.externalReference : '')}
                </strong>
              </div>
              <div>
                <i className='caption2medium'>{row.desc}</i>
              </div>
              {!rowIsEditMode(row) && (
                <>
                  {props.showUnitPrice && (row.tgapValue ?? row.repValue ?? 0) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingBasePrice')}</i>
                    </div>
                  )}
                  {props.showUnitPrice && (row.transportValue ?? 0) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTransport')}</i>
                    </div>
                  )}
                  {!props.hideRep && props.showUnitPrice && (row.repValue ?? 0) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingRepTax')}</i>
                    </div>
                  )}
                  {props.showUnitPrice && (row.tgapValue ?? 0) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTgap')}</i>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {rowIsEditMode(row) && props.canEditRowProduct && (
            <SelectProduct
              registerName='products'
              error={''}
              isSelectable={true}
              isPopup={false}
              titleSize='none'
              selectedOptionChanged={(selectedOption: any) => {
                if (selectedOption && selectedOption.data) {
                  dispatch(productItemsActions.editProduct({ product: row, newValue: selectedOption.data }));
                }
              }}
              forceSelectedOption={{
                value: row.productId,
                label: row.label,
              }}
              clearOnSelect={true}
              inRow={true}
              isClearable={false}
            />
          )}
        </>
      ),
      sortable: false,
      right: false,
      id: 'label',
      grow: 2,
    },
    {
      name: <div className='body1 body1-header'>{t('common.comment')}</div>,
      selector: (row: ProductItemRow) => `${row.comment}`,
      cell: (row: ProductItemRow) => (
        <div className='column-align-left'>
          <div>
            <i className='caption2medium'>{row.comment ? addLineBreak(row.comment) : undefined}</i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'comment',
      omit: !((props.showProductComment && products.find((x) => x.comment ?? '' !== '')) ?? false),
      grow: 2,
    },
    {
      name: <div className='body1 body1-header'>{t('common.price')}</div>,
      selector: (row: ProductItemRow) => row.itemUnitPrice,
      cell: (row: ProductItemRow) => (
        <>
          <div>
            <InputLabel
              labelValue={
                priceUtils.productUnitPrice(row)
                  ? (rowIsEditMode(row)
                      ? StringUtils.currencyFormat(row.itemUnitPrice, currentCurrency)
                      : StringUtils.currencyFormat(priceUtils.productUnitPrice(row), currentCurrency)) +
                    ' / ' +
                    t('unitShort.' + row.unit)
                  : '-'
              }
              canEditField={props.canEditUnitPrice ?? false}
              parentHasEditMode={props.rowsHaveEditMode ?? false}
              editingParent={rowIsEditMode(row) ?? false}
              onChange={(newValue: string | number) => {
                if (props.canEditUnitPrice) {
                  dispatch(
                    productItemsActions.editProductUnitPrice({
                      product: row,
                      newValue: (newValue as number) ?? 0,
                    }),
                  );
                }
              }}
              value={row.itemUnitPrice ?? 0}
              suffix={StringUtils.currencySymbol(currentCurrency) + ' / ' + t('unitShort.' + row.unit)}
              error={''}
              inRow={true}
              type='number'
              number={{ min: 0, max: 99999999, step: 1 }}
            />
            {!rowIsEditMode(row) && (
              <>
                {props.showUnitPrice && (row.tgapValue ?? row.repValue ?? 0) > 0 && (
                  <div className='column-align-left'>
                    <i className='caption2medium'>{StringUtils.currencyFormat(row.itemUnitPrice, currentCurrency)}</i>
                  </div>
                )}
                {(row.transportValue ?? 0) > 0 && (
                  <div className='column-align-left'>
                    <i className='caption2medium'>{StringUtils.currencyFormat(row.transportValue, currentCurrency)}</i>
                  </div>
                )}
                {!props.hideRep && (row.repValue ?? 0) > 0 && (
                  <div className='column-align-left'>
                    <i className='caption2medium'>{StringUtils.currencyFormat(row.repValue, currentCurrency)}</i>
                  </div>
                )}
                {(row.tgapValue ?? 0) > 0 && (
                  <div className='column-align-left'>
                    <i className='caption2medium'>{StringUtils.currencyFormat(row.tgapValue, currentCurrency)}</i>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ),
      sortable: false,
      center: true,
      id: 'genericUnitPrice',
      omit: !(props.showUnitPrice ?? false),
      grow: 1,
    },
    {
      name: <div className='body1 body1-header'>{t('common.vatRate')}</div>,
      selector: (row: ProductItemRow) => row.vatRateId,
      cell: (row: ProductItemRow) => (
        <>
          {(!rowIsEditMode(row) || !props.canEditVatRate) && (
            <div className='column-align-center'>
              <div>
                <strong className='body1'>
                  {row.vatRate !== undefined ? roundNumber(row.vatRate * 100, 4) + '%' : '-'}
                </strong>
              </div>
            </div>
          )}
          {rowIsEditMode(row) && props.canEditVatRate && (
            <SelectVatRate
              registerName='vatRate'
              error={''}
              isSelectable={true}
              isPopup={false}
              titleSize='none'
              selectedOptionChanged={(selectedOption: any) => {
                if (selectedOption && selectedOption.data) {
                  dispatch(productItemsActions.editProductVatRate({ product: row, newValue: selectedOption.data }));
                }
              }}
              forceSelectedOption={{
                value: row.vatRateId,
                label: row.vatRate !== undefined ? roundNumber(row.vatRate * 100, 4) + '%' : '-',
              }}
              clearOnSelect={true}
              inRow={true}
              isClearable={false}
            />
          )}
        </>
      ),
      sortable: false,
      center: true,
      button: false,
      id: 'vatRate',
      omit: !(props.showVatRate ?? false),
      grow: 1,
    },
    {
      name: (
        <div className='body1 body1-header'>
          {props.showDeliveredQuantity ? t('common.orderedQuantity') : t('common.quantity')}
        </div>
      ),
      selector: (row: ProductItemRow) => row.orderedQuantity,
      cell: (row: ProductItemRow) => (
        <InputLabel
          labelValue={(row.orderedQuantity ?? '0') + ' ' + t('unitShort.' + row.unit)}
          canEditField={props.canEditOrderedQuantity ?? false}
          parentHasEditMode={props.rowsHaveEditMode ?? false}
          editingParent={rowIsEditMode(row) ?? false}
          onChange={function (newValue: string | number): void {
            if (props.canEditOrderedQuantity) {
              dispatch(
                productItemsActions.editProductOrderedQuantity({
                  product: row,
                  newValue: (newValue as number) ?? 1,
                }),
              );
            }
          }}
          value={row.orderedQuantity ?? ''}
          suffix={!row.quantityIsSum ? t('unitShort.' + row.unit) : ' '}
          disabled={row.quantityIsSum || !props.canEditOrderedQuantity}
          error={''}
          inRow={true}
          type='number'
          number={{ min: 0, max: 99999999, step: 1 }}
        />
      ),
      sortable: false,
      center: true,
      id: 'orderedQuantity',
      omit: !(props.showOrderedQuantity ?? false),
      grow: 1,
    },
    {
      name: (
        <div className='body1 body1-header'>
          {props.showOrderedQuantity ? t('common.deliveredQuantity') : t('common.quantity')}
        </div>
      ),
      selector: (row: ProductItemRow) => row.deliveredQuantity,
      cell: (row: ProductItemRow) => (
        <InputLabel
          labelValue={(row.deliveredQuantity ?? '0') + ' ' + t('unitShort.' + row.unit)}
          canEditField={props.canEditDeliveredQuantity ?? false}
          parentHasEditMode={props.rowsHaveEditMode ?? false}
          editingParent={rowIsEditMode(row) ?? false}
          onChange={function (newValue: string | number): void {
            if (props.canEditDeliveredQuantity) {
              dispatch(
                productItemsActions.editProductDeliveredQuantity({
                  product: row,
                  newValue: (newValue as number) ?? 1,
                }),
              );
            }
          }}
          value={row.deliveredQuantity ?? 0}
          suffix={!row.isService ? t('unitShort.' + row.unit) : ' '}
          disabled={!props.canEditDeliveredQuantity}
          error={''}
          inRow={true}
          type='number'
          number={{ min: 0, max: 99999999, step: 1 }}
        />
      ),
      sortable: false,
      center: true,
      id: 'deliveredQuantity',
      omit: !(props.showDeliveredQuantity ?? false),
      grow: 1,
    },
    {
      name: (
        <>
          <span className='body1 body1-header' title={t('common.transportModesDesc')}>
            {t('common.transport')}
          </span>
          <TooltipIcon
            icon={'question'}
            message={
              props.hideIncludedTransportMode
                ? t('common.transportModesDescNoIncluded')
                : t('common.transportModesDesc')
            }
          />
        </>
      ),
      selector: (row: ProductItemRow) => row.enableTransportPrice,
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <SelectTransportMode
              setValue={undefined}
              register={undefined}
              registerName='transportMode'
              titleOverride=''
              error={''}
              selectedOptionChanged={(selectedOption: SelectTransportModeOption) => {
                if (props.canEditTransportMode) {
                  dispatch(
                    productItemsActions.editProductTransportMode({
                      product: row,
                      newValue: selectedOption.value,
                    }),
                  );

                  if (
                    selectedOption.value === TransportMode.DELIVERED &&
                    products?.findIndex((x) => x.isTransport) === -1
                  ) {
                    dispatch(loadTransportServices({ id: row.vatRateId, vatRate: row.vatRate } as VatRate));
                  }
                }
              }}
              isPopup={false}
              onClosePopup={undefined}
              searchParent={''}
              dependsOnSearchParent={false}
              forceSelectedOption={{
                value: row.transportMode ?? TransportMode.EXWORKS,
                label: t('transportMode.' + (row.transportMode ?? TransportMode.EXWORKS)),
              }}
              isSelectable={props.canEditTransportMode ?? true}
              inRow={true}
              isClearable={false}
              hideIncludedTransportMode={props.hideIncludedTransportMode}
            />
          )}
        </>
      ),
      sortable: false,
      left: true,
      button: false,
      id: 'transportMode',
      omit: !(props.showTransportMode ?? false),
      grow: 1,
    },
    {
      name: (
        <div className='table-header'>
          <div className='table-header-column--box'>
            <div className='table-header-column--text'>{t('common.discount')}</div>
            <SymbolSwitch
              onChange={onChangeDiscountMode}
              checked={discountMode === '%'}
              leftSymbol={'€'}
              rightSymbol={'%'}
            />
          </div>
        </div>
      ),
      selector: (row: ProductItemRow) => row.discountPercent,
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <div className='column'>
              <div className='firstRow'>
                <Input
                  onChange={function (newValue: string | number): void {
                    if (props.canEditDiscountValue) {
                      const val =
                        discountMode === '%'
                          ? (newValue as number) / 100.0
                          : (newValue as number) / (priceUtils.productUnitPrice(row) ?? 1);
                      dispatch(
                        productItemsActions.editProductDiscountValue({
                          product: row,
                          newValue: roundNumber(val, 4),
                        }),
                      );
                    }
                  }}
                  error={''}
                  value={roundNumber(priceUtils.discountValue(row, discountMode === '%' ? '%' : '€'), 2)}
                  suffix={discountMode === '%' ? '%' : '€'}
                  disabled={!props.canEditDiscountValue}
                  inRow={true}
                  type='number'
                  number={{
                    min: 0.0,
                    max: discountMode === '%' ? 100.0 : priceUtils.productUnitPrice(row),
                    step: discountMode === '%' ? 0.1 : roundNumber(0.01 * priceUtils.productUnitPrice(row), 2),
                  }}
                />
              </div>
            </div>
          )}
        </>
      ),
      sortable: false,
      left: true,
      id: 'discount',
      omit: true, // TODO : revoir fonctionnement discount !(props.showDiscountValue ?? false),
      grow: 1.8,
    },
    {
      name: <div className='body1 body1-header'>{t('common.transportPrice')}</div>,
      selector: (row: ProductItemRow) => priceUtils.totalTransportPrice(row),
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <div className='column-align-right'>
              <strong className='body1'>
                {StringUtils.currencyFormat(priceUtils.totalTransportPrice(row), currentCurrency) ?? '-'}
              </strong>
            </div>
          )}
        </>
      ),
      sortable: false,
      right: true,
      id: 'transportPrice',
      omit: true, // disabled, TODO : review properly when doing invoices ? // allowMultipleSites ?? false,
      grow: 1,
    },
    {
      name: <div className='body1 body1-header'>{t('common.invoice')}</div>,
      selector: (row: ProductItemRow) => row.invoiceId,
      cell: (row: ProductItemRow) => (
        <>
          {row.invoice && (
            <Link className='table-link' to={seeInvoiceDetail(row)}>
              <a>
                {t('invoiceType.' + row.invoice.type) +
                  ' ' +
                  row.invoice.invoiceNumber +
                  (row.invoice.externalReference ? '-' + row.invoice.externalReference : '') +
                  (row.invoice.type === InvoiceType.PROFORMA
                    ? ''
                    : ' - ' +
                      t(
                        'status.' +
                          (row.invoice.status === InvoiceStatus.UPCOMING &&
                          row.invoice.invoiceDueDate !== undefined &&
                          moment(row.invoice.invoiceDueDate) < moment(new Date())
                            ? 'overdue'
                            : row.invoice.status),
                      ))}
              </a>
            </Link>
          )}
        </>
      ),
      sortable: false,
      button: true,
      center: true,
      id: 'invoice',
      omit: !((props.showInvoicing && (products.find((x) => (x.invoiceId ?? '') !== '') ?? false)) ?? false),
      grow: 1,
    },
    {
      name: <div className='body1 body1-header'>{t('common.totalExVat')}</div>, // temp : TODO : put back when transportPrice is back t('common.productPrice'),
      selector: (row: ProductItemRow) => priceUtils.totalProductPrice(row),
      cell: (row: ProductItemRow) => (
        <div className='column-align-right'>
          <strong className='body1'>
            {StringUtils.currencyFormat(priceUtils.totalProductPrice(row), currentCurrency) ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: true,
      id: 'productPrice',
      omit: !((props.showProductPrice && (products.find((x) => (x.itemUnitPrice ?? 0) !== 0) ?? false)) ?? true),
      grow: 1,
    },
    {
      name: (
        <div className='body1 body1-header'>
          {props.showProductPrice || props.showRowExVatPrice ? t('common.totalInclVat') : t('common.inclVat')}
        </div>
      ),
      selector: (row: ProductItemRow) => priceUtils.inclVatPrice(row),
      cell: (row: ProductItemRow) => (
        <div className='column-align-right'>
          <strong className='body1'>
            {StringUtils.currencyFormat(Math.round(priceUtils.inclVatPrice(row) * 100) / 100, currentCurrency) ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: true,
      id: 'inclVatPrice',
      omit: !(props.showRowInclVatPrice ?? false),
      grow: 1,
    },
    {
      name: '',
      sortable: false,
      cell: (row: any) => (
        <>
          {(!row.isService || row.isTransport) && (row.invoiceId ?? '') === '' && (
            <div
              className='column-last'
              onClick={(e: any) => {
                onClickDeleteProduct(row);
              }}
            >
              <Icon icon={'close'} />
            </div>
          )}
        </>
      ),
      button: true,
      left: true,
      id: 'remove',
      omit: !(props.canRemove ?? false),
      minWidth: '3%',
      grow: 1,
    },
  ];

  function onChangeDiscountMode() {
    setDiscountMode((prevState: string) => {
      return prevState === '%' ? '€' : '%';
    });
  }

  const onClickDeleteProduct = (e: any) => {
    setIsOpenConfirmDeleteProduct(true);
    setCurrentProductToDelete(e);
  };

  const onDeleteProductOk = (e: ProductItemRow) => {
    if (props.canRemove) dispatch(productItemsActions.removeProduct(e));
    setIsOpenConfirmDeleteProduct(false);
  };

  function handleGenerateInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/invoice/generate-from-trip-products';

    const dataToSend: GenerateInvoicesParameters = {
      invoiceType: InvoiceType.PROFORMA,
      tripProducts: products?.filter((x) => (x.invoiceId ?? '') === '')?.map((x) => x.id ?? '') ?? [],
    };

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        addToast(t('common.proformaGenerated'), ToastTypes.success);
        history('/invoices/' + res.data.content.invoices[0] + '/update');
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function rowIsEditMode(row: ProductItemRow) {
    return !props.rowsHaveEditMode || row.editMode || parentIsEditMode(row);
  }

  function switchRowEditMode(row: RowParent, newValue: boolean) {
    expandCollapseParentRows(newValue, row);
    setEditingParentRow(newValue);
  }

  function expandCollapseParentRows(expanded: boolean, row: RowParent) {
    if (!expanded || row.id !== expandedParentRowId) setEditingParentRow(false);
    setExpandedParentRowId(expanded ? row.id ?? '' : '');
  }

  function parentIsEditMode(row: ProductItemRow) {
    return (
      editingParentRow &&
      parentRows.find((x) => x.id === expandedParentRowId)?.rowProducts?.find((y) => y.id === row.id) !== undefined
    );
  }

  const throttledhandleGenerateInvoice = throttle({ interval: 1000 }, handleGenerateInvoice);

  let dataTableToShow = <></>;
  if (props.displayMode) {
    dataTableToShow = (
      <DataTable
        className='table'
        customStyles={parentTableStyles}
        columns={setParentProductColumns(
          props.displayMode === 'buildingSites' ? 'buildingSites' : 'trips',
          props.parentPriceLabel ?? t('common.totalPrice'),
          siteTimeZone,
          new PriceUtils({
            showTotal: true,
            showOrderedQuantity: false,
            showDeliveredQuantity: true,
            hideRep: false,
          }),
          switchRowEditMode,
          expandedParentRowId,
          props.rowsHaveEditMode,
          editingParentRow ?? false,
        )}
        data={parentRows}
        noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
        highlightOnHover
        sortServer
        expandableRows
        expandOnRowClicked={true}
        expandableRowsComponent={({ data: parentData }) => (
          <DataTable
            className='table'
            customStyles={childTableStyles}
            columns={columns}
            data={
              parentData.rowProducts
                ? parentData.rowProducts
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .sort((a, b) => Number(a.trip?.tripNumber ?? 0) - Number(b.trip?.tripNumber ?? 0))
                : []
            }
            noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
            sortServer
          />
        )}
        expandableRowExpanded={(row: RowParent) => row.id === expandedParentRowId}
        onRowExpandToggled={expandCollapseParentRows}
      />
    );
  } else {
    dataTableToShow = (
      <DataTable
        className='table'
        customStyles={TableStyles}
        columns={columns}
        data={products}
        noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
        sortServer
      />
    );
  }

  return (
    <>
      {props.isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          {dataTableToShow}
          {props.canAdd && (
            <SelectProduct
              registerName='products'
              error={''}
              isSelectable={true}
              isPopup={false}
              titleSize='none'
              selectedOptionChanged={(selectedOption: any) => {
                if (selectedOption) {
                  dispatch(productItemsActions.addProduct(selectedOption.data));
                }
              }}
              clearOnSelect={true}
            />
          )}
          {props.showTotal && invoiceManagement && (products.find((x) => x.itemUnitPrice != null) ?? false) && (
            <div className={`table-row ${props.displayMode ? 'table-row-padded' : ''}`}>
              {props.showInvoicing && (products.find((x) => (x.invoiceId ?? '') === '') ?? false) && (
                <div className='table-row-content table-row-content-left'>
                  <Button
                    onClick={() => throttledhandleGenerateInvoice()}
                    label={t('common.invoiceAction')}
                    size='large'
                  />
                </div>
              )}
              <div className='table-row-content table-row-content-right py-4'>
                <div className='table-row-column--text-box'>
                  <div className='table-row-column--text-main'>{t('common.totalExVat')}</div>
                  <div className='table-row-column--text-main'>{t('common.vat')}</div>
                  <div className='table-row-column--text-main'>{t('common.totalInclVat')}</div>

                  {priceUtils.getTotalTransportValue(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTransport')}</i>
                    </div>
                  )}
                  {!props.hideRep && priceUtils.getTotalRepTax(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingRepTax')}</i>
                    </div>
                  )}
                  {priceUtils.getTotalTgap(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTgap')}</i>
                    </div>
                  )}
                </div>
                <div className='table-row-column--text-box table-row-column--text-box--right'>
                  <div className='table-row-column--text-main'>
                    {StringUtils.currencyFormat(priceUtils.getTotalExVat(products), currentCurrency)}
                  </div>
                  <div className='table-row-column--text-main'>
                    {StringUtils.currencyFormat(priceUtils.getTotalVat(products), currentCurrency)}
                  </div>
                  <div className='table-row-column--text-main'>
                    {StringUtils.currencyFormat(priceUtils.getTotalPrice(products), currentCurrency)}
                  </div>

                  {priceUtils.getTotalTransportValue(products) > 0 && (
                    <div>
                      <i className='caption2medium'>
                        {StringUtils.currencyFormat(priceUtils.getTotalTransportValue(products), currentCurrency)}
                      </i>
                    </div>
                  )}
                  {!props.hideRep && priceUtils.getTotalRepTax(products) > 0 && (
                    <div>
                      <i className='caption2medium'>
                        {StringUtils.currencyFormat(priceUtils.getTotalRepTax(products), currentCurrency)}
                      </i>
                    </div>
                  )}
                  {priceUtils.getTotalTgap(products) > 0 && (
                    <div>
                      <i className='caption2medium'>
                        {StringUtils.currencyFormat(priceUtils.getTotalTgap(products), currentCurrency)}
                      </i>
                    </div>
                  )}
                </div>
                <div className='table-row-column'></div>
              </div>
            </div>
          )}
        </>
        // TOTAL HT 4 000,00 €
        // REMISE GLOBALE HT -400,00 €
        // TOTAL NET HT 3 600,00 €
        // TOTAL TVA 720,00 €
        // TOTAL 4 320,00 €
      )}

      {isOpenConfirmDeleteProduct && (
        <PopupContainer
          message={`${t('common.confirmDelete')} '${currentProductToDelete?.label}' ?`}
          onCancel={function (): void {
            setIsOpenConfirmDeleteProduct(false);
          }}
          onConfirm={function (): void {
            setIsOpenConfirmDeleteProduct(false);
            if (currentProductToDelete) onDeleteProductOk(currentProductToDelete);
          }}
          confirmLabel={t('common.yes')}
          confirmIcon='delete'
          cancelLabel={t('common.no')}
        />
      )}
    </>
  );
};
