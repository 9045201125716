import React, { ReactNode } from 'react';

type LoginLayoutProps = {
  children: ReactNode;
};

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <main className='flex min-h-screen'>
      <div className='leftImage min-h-screen w-1/2'></div>
      <div className='flex w-1/2 items-center justify-center'>{children}</div>
    </main>
  );
};

export default LoginLayout;
