import * as React from 'react';

import { cn } from '../../../../lib/utils';

export interface InputUIProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disableStyleComboboxInput?: boolean;
}

const InputUI = React.forwardRef<HTMLInputElement, InputUIProps>(
  ({ className, disableStyleComboboxInput, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50',
          !disableStyleComboboxInput &&
            'border border-input focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
InputUI.displayName = 'InputUI';

export { InputUI };
