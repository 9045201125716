import React from 'react';
import i18next from '../../i18n';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/molecules/Form';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/molecules/Card';
import { Input } from '../ui/atoms/input/Input';
import { Button } from '../ui/atoms/button/Button';
import { Buffer } from 'buffer';
import Alert from '../ui/molecules/alert/Alert';
import { REDIRECT_COUNTDOWN_IN_SECOND, HOME_URL } from '../../constants/constants';
import useFetchData from '../../hooks/useFetchData';
import { resetPassword } from '../../services/auth';
import { useCountdownRedirect } from '../../hooks/useCountDownRedirect';

const resetPasswordSchema = yup.object().shape({
  email: yup.string().email(i18next.t('login.invalidEmail')).required(i18next.t('login.requiredEmail')),
  password: yup
    .string()
    .required(i18next.t('login.requiredPassword'))
    .matches(/.{8,20}/, i18next.t('login.invalidPassword')),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], i18next.t('login.resetPassword.passwordsMissmatch')),
});

type Credentials = yup.InferType<typeof resetPasswordSchema>;

type ResetPasswordFormProps = {
  username: string;
  resetToken: string;
};

type ResetCredentials = ResetPasswordFormProps & {
  password: string;
};

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ username, resetToken }) => {
  const { t } = useTranslation();

  const { isLoading, isSuccess, errorMessage, fetchDataHandler } = useFetchData<void, ResetCredentials>({
    fetchData: (resetCredentials) =>
      resetCredentials ? resetPassword(resetCredentials) : Promise.reject(new Error('resetCredentials is undefined')),
    errorMessages: {
      ERR4001003: t('login.invalidPassword'),
      ERR4010008: t('login.resetPassword.results.expired'),
      ERR4010009: t('login.resetPassword.results.invalid'),
      ERR4009999: t('login.resetPassword.results.invalid'),
      ERR4010000: t('login.resetPassword.results.invalid'),
      ERR5000000: t('login.serverError'),
      default: t('login.errorMessage'),
    },
  });

  const form = useForm<Credentials>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const countdown = useCountdownRedirect({
    isSuccess,
    initialCountdown: REDIRECT_COUNTDOWN_IN_SECOND,
    url: HOME_URL,
  });

  const handleNewPasswordSubmit = async (credentials: Credentials) => {
    fetchDataHandler({
      username: username && username.length > 0 ? username : form.getValues('email'),
      password: Buffer.from(credentials.password).toString('base64'),
      resetToken,
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleNewPasswordSubmit)}>
        <Card className='w-96'>
          <CardHeader className='items-center'>
            <CardTitle>{t('login.resetPassword.title')}</CardTitle>
          </CardHeader>

          <CardContent>
            {!username && (
              <FormField
                control={form.control}
                name='email'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('login.email')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type='email'
                        placeholder='exemple@email.com'
                        value={form.getValues('email') ?? username}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('login.password')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type='password'
                      placeholder={t('login.resetPassword.newPassword')}
                      disabled={isSuccess}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='passwordConfirmation'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('login.passwordConfirmation')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type='password'
                      placeholder={t('login.resetPassword.confirmPassword')}
                      disabled={isSuccess}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className='flex-col items-start'>
            <Button disabled={isSuccess}>{t('login.resetPassword.reset')}</Button>

            {isLoading ? <div>Loading</div> : null}
            {errorMessage && !isSuccess && !isLoading ? (
              <Alert className='mt-4' variant='destructive' description={errorMessage} />
            ) : null}
            {isSuccess ? (
              <Alert
                className='mt-4'
                variant='success'
                description={`${t('login.resetPassword.results.success')} ${countdown} sec`}
              />
            ) : null}
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};
export default ResetPasswordForm;
