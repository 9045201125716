import React from 'react';
import { cn } from '../../../../lib/utils';

type ActionbarProps = {
  actionButtons: React.ReactNode[];
  className?: string;
};

const Actionbar: React.FC<ActionbarProps> = ({ actionButtons, className }) => {
  return (
    <div className={cn('flex items-center px-5 py-3.5 gap-2.5 bg-background', className)}>
      {actionButtons.map((button, index) => (
        <React.Fragment key={index}>{button}</React.Fragment>
      ))}
    </div>
  );
};

export default Actionbar;
