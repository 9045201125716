import { Table } from '@tanstack/react-table';

export type TableState<TData> = {
  tableInstance: Table<TData> | null;
};

export type TableAction<TData> = { type: 'SET_TABLE_INSTANCE'; payload: Table<TData> };

const tableReducer = <TData>(state: TableState<TData>, action: TableAction<TData>): TableState<TData> => {
  switch (action.type) {
    case 'SET_TABLE_INSTANCE':
      return { ...state, tableInstance: action.payload };
    default:
      return state;
  }
};

export { tableReducer };
