import { createInstance } from '../Config';
import { GetStats } from '../models/Stat';

const instance = createInstance({ isExtranet: true });

export const fetchStats = async ({
  accessToken,
  orgid,
}: {
  accessToken: string;
  orgid: string;
}): Promise<GetStats> => {
  const response = await instance.get(`${orgid}/trip/stats`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return response.data.content;
};
