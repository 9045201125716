import { useEffect, useState } from 'react';
import { ApiErrorMessages, decodeApiError } from '../utils/apiErrorHandler';
import { AxiosError } from 'axios';

type UseFetchDataProps<T, A = undefined> = {
  fetchData: (arg?: A, controller?: AbortController) => Promise<T>;
  pageNumber?: number;
  errorMessages: ApiErrorMessages;
  query?: string;
};

type UseFetchDataResult<T, A = undefined> = {
  data: T | null;
  isLoading: boolean;
  isSuccess: boolean;
  errorMessage: string | null;
  fetchDataHandler: (arg?: A, controller?: AbortController) => Promise<void>;
};

const useFetchData = <T, A = undefined>({
  fetchData,
  errorMessages,
  query,
}: UseFetchDataProps<T, A>): UseFetchDataResult<T, A> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setData(null);
  }, [query]);

  const fetchDataHandler = async (arg?: A, controller?: AbortController) => {
    setIsLoading(true);

    try {
      const response = await fetchData(arg, controller);
      setData(response);
      setIsSuccess(true);
    } catch (error) {
      const isCanceledError =
        (error as AxiosError).name === 'CanceledError' || (error as AxiosError).code === 'ERR_CANCELED';
      if (!isCanceledError) {
        const message = decodeApiError(error as AxiosError, errorMessages);
        setErrorMessage(message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, isSuccess, errorMessage, fetchDataHandler };
};

export default useFetchData;
