import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { Provider } from 'react-redux';
import store from './store/index';

PiwikPro.initialize('cb75c074-5014-49c0-b5c9-a3e8aafa57b3', 'https://dunemanager.containers.piwik.pro');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
