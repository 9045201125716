import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { SelectCustomer, SelectCustomerOption } from '../../forms/SelectCustomer';
import { Input } from '../../../stories/dune/atoms/Input';
import { BuildingSiteSave, GetBuildingSite } from '../../../models/BuildingSite';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import countryList from '../../Countries';
import Switch from 'react-switch';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';
import { SelectLocality, SelectLocalityOption } from '../../forms/SelectLocality';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { ManageDocument } from '../../forms/ManageDocument';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchCustomerDetails } from '../../../services/customer';
import { createBuildingSite, updateBuildingSite } from '../../../services/buildingSite';
import { ToastTypes } from '../../../models/ToastTypes';
import useAddContact from '../../../hooks/useAddContact';

interface GeneralBuildingSiteProps {
  buildingSiteId?: string;
  buildingSiteData: GetBuildingSite | undefined;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  mode: string;
  dataParent?: string;
}

const GeneralBuildingSite = React.memo(
  ({ buildingSiteId, mode, buildingSiteData, onConfirm, onCancel, dataParent }: GeneralBuildingSiteProps) => {
    const history = useNavigate();
    const { addToast } = useToast();
    const { token, orgid } = useAuthGuard();
    const isEditMode = mode === 'update';

    const [saveBuildingSite, setSaveBuildingSite] = useState<BuildingSiteSave>();

    const [isActive, setIsActive] = useState<boolean>(true);

    const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(
      buildingSiteData?.customer
        ? {
            value: buildingSiteData.customerId,
            label: buildingSiteData.customer.label || '',
            data: buildingSiteData.customer,
          }
        : null,
    );
    const [label, setLabel] = useState<string>();
    const [externalReference, setExternalReference] = useState<string>();
    const [tvaNumber, setTvaNumber] = useState<string>();

    const [streetAddress, setStreetAddress] = useState<string>();
    const [locality, setLocality] = useState<string>();
    const [postalCode, setPostalCode] = useState<string>();
    const [localityOption, setLocalityOption] = useState<SelectLocalityOption | null>(
      buildingSiteData?.address?.locality
        ? {
            value: 'TODO-INSEE',
            label: buildingSiteData?.address?.locality ?? '',
            data: {
              code: 'TODO-INSEE',
              nom: buildingSiteData?.address?.locality ?? '',
              codesPostaux: [buildingSiteData?.address?.postalCode ?? ''],
            },
          }
        : null,
    );
    const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
      buildingSiteData?.address?.country
        ? {
            value: buildingSiteData.address.country,
            label: countryList[buildingSiteData.address.country],
            data: {
              value: buildingSiteData.address.country,
              label: countryList[buildingSiteData.address.country],
            },
          }
        : {
            value: 'FR',
            label: countryList['FR'],
            data: { value: 'FR', label: countryList['FR'] },
          },
    );

    const [customerIsExemptFromRepTax, setCustomerIsExemptFromRepTax] = useState<boolean>(false);
    const [isExemptFromRepTax, setIsExemptFromRepTax] = useState<boolean>(false);
    const [isSubjectToRepTax, setIsSubjectToRepTax] = useState<boolean>(false);

    const [firstname, setFirstname] = useState<string>();
    const [lastname, setLastname] = useState<string>();
    const [telephone, setTelephone] = useState<string>();
    const [email, setEmail] = useState<string>();

    const [buildingSiteDataCustom, setBuildingSiteDataCutom] = useState<DataCustomItem>({});

    const [globalParameters, setGlobalParameters] = useState<GlobalParameter[]>([]);

    interface IFormInputs {
      label: string;
      client: {
        value: string;
        label: string;
      };
      externalReference?: string;
      country?: {
        value: string;
        label: string;
      };
      postalCode?: string;
      locality?: {
        value: string;
        label: string;
      };
      streetAddress: string;
      tvaNumber?: string;
      sendTripToSxd?: boolean;
      allBuildingSites?: boolean;
      supplier?: boolean;
      firstname?: string;
      lastname?: string;
      telephone?: string;
      email?: string;
    }

    const schema = yup.object().shape({
      label: yup.string().when([], (fields, schema) => {
        const shouldNotRequireLabel =
          globalParameters.findIndex((x) => x.label === 'operatorMustFillLabelBuildingSite' && x.value === 'false') !==
          -1;

        return shouldNotRequireLabel ? schema.notRequired() : schema.required('Le nom est obligatoire');
      }),
      client: yup.object().shape({
        value: yup.string().required('Le client est obligatoire'),
        label: yup.string().required('Le client est obligatoire'),
      }),
      streetAddress: yup.string().when([], (fields, schema) => {
        const shouldNotRequireStreetAddress =
          globalParameters.findIndex(
            (x) => x.label === 'operatorMustFillAddressBuildingSite' && x.value === 'false',
          ) !== -1;

        return shouldNotRequireStreetAddress ? schema.notRequired() : schema.required("L'adresse est obligatoire");
      }),
      locality: yup.object().when([], (fields, schema) => {
        const shouldRequireLocality =
          globalParameters.findIndex(
            (x) => x.label === 'operatorMustFillLocalityBuildingSite' && x.value === 'true',
          ) !== -1;

        return shouldRequireLocality
          ? schema.shape({
              value: yup.string().nullable().required('La ville est obligatoire'),
              label: yup.string().nullable().required('La ville est obligatoire'),
            })
          : schema.nullable().notRequired();
      }),
      firstname: yup.string().when(['lastname', 'telephone', 'email'], ([lastname, telephone, email], schema) => {
        const shouldRequireFirstname = lastname?.length > 0 || telephone?.length > 0 || email?.length > 0;

        return shouldRequireFirstname ? schema.required('Le prénom doit également être rempli') : schema.notRequired();
      }),
      email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
        return (value ?? '').length === 0 || isEmail(value ?? '');
      }),
    }); // on utilise un validateur de 'validator.js' pour correspondre au back

    const {
      setValue,
      register,
      formState: { errors },
      handleSubmit,
    } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
      reValidateMode: 'onBlur',
    });

    useEffect(() => {
      if (buildingSiteData) {
        // on initialise SaveBuildingSite pour ne pas perdre les données non-gérées lors de la sauvegarde
        setSaveBuildingSite({ ...buildingSiteData });

        setIsActive(buildingSiteData?.isActive ?? true);

        if (buildingSiteData.customer) {
          setValue('client', {
            value: buildingSiteData.customer.id ?? '',
            label: buildingSiteData.customer.label ?? '',
          });
          setCustomerOption({
            value: buildingSiteData.customer.id ?? '',
            label: buildingSiteData.customer.label ?? '',
            data: buildingSiteData.customer,
          });
        }

        setLabel(buildingSiteData?.label);
        setValue('label', buildingSiteData?.label ?? '');
        setExternalReference(buildingSiteData?.externalReference);
        setTvaNumber(buildingSiteData?.tvaNumber);

        setStreetAddress(buildingSiteData?.address?.streetAddress);
        setValue('streetAddress', buildingSiteData?.address?.streetAddress ?? '');
        setLocality(buildingSiteData?.address?.locality);
        setPostalCode(buildingSiteData?.address?.postalCode);
        if (buildingSiteData?.address?.locality)
          setLocalityOption({
            value: buildingSiteData?.address?.inseeCode ?? '',
            label: buildingSiteData?.address?.locality ?? '',
            data: {
              code: buildingSiteData?.address?.inseeCode ?? '',
              nom: buildingSiteData?.address?.locality ?? '',
              codesPostaux: [buildingSiteData?.address?.postalCode ?? ''],
            },
          });
        if (buildingSiteData?.address?.country)
          setCountryOption({
            value: buildingSiteData?.address?.country,
            label: countryList[buildingSiteData?.address?.country],
          });

        setCustomerIsExemptFromRepTax(buildingSiteData?.customer?.isExemptFromRepTax ?? false);
        setIsExemptFromRepTax(buildingSiteData?.isExemptFromRepTax ?? false);
        setIsSubjectToRepTax(buildingSiteData?.isSubjectToRepTax ?? false);

        setBuildingSiteDataCutom(buildingSiteData?.dataCustom as any);
      }
    }, [buildingSiteData]);

    useEffect(() => {
      const fetchCurrentCustomerDetails = async (id?: string) => {
        if (!token || !orgid || !id) {
          return;
        }

        try {
          const fetchedCustomerDetails = await fetchCustomerDetails({ accessToken: token, orgid, customerId: id });

          const { id: fetchedCustomerId, label } = fetchedCustomerDetails;
          setValue('client', {
            value: fetchedCustomerId,
            label: label || '',
          });

          setCustomerOption({
            value: fetchedCustomerId,
            label: label || '',
            data: fetchedCustomerDetails,
          });
        } catch (error) {
          const isError = axios.isAxiosError(error) && error.response;
          isError ? handleApiError({ error, addToast, history }) : undefined;
        }
      };

      fetchCurrentCustomerDetails(dataParent);
    }, [dataParent]);

    useEffect(() => {
      const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
      setGlobalParameters(globalParametersSite);
    }, []);

    const onSubmit = async () => {
      try {
        if (!token || !orgid) {
          return;
        }

        const payload: BuildingSiteSave = stringUtils.formatFieldsForPost({
          ...saveBuildingSite,
          label: label ?? saveBuildingSite?.locality ?? streetAddress ?? '',
          externalReference,
          tvaNumber,
          postalCode,
          streetAddress,
          isExemptFromRepTax,
          isSubjectToRepTax,
          isActive,
        });

        if (isEditMode && buildingSiteId) {
          await updateBuildingSite({
            orgid,
            accessToken: token,
            buildingSiteId,
            payload,
          });
        } else {
          const { uuid } = await createBuildingSite({ orgid, accessToken: token, payload });
          addContact(uuid);
          onConfirm(uuid);
        }
        addToast(t('common.buildingSite' + (isEditMode ? 'Updated' : 'Created')), ToastTypes.success);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      }
    };

    const addContact = useAddContact({ email, firstname, lastname, telephone });

    return !buildingSiteData && mode === 'update' ? (
      <div>Loading...</div>
    ) : (
      <form id='form' onSubmit={handleSubmit(onSubmit)}>
        <CardDetail>
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.generalInformations')} type='title1' />

              {mode === 'update' && (
                <FormRow align='right' alignVert='center'>
                  <h3 className='base2'>{t('common.active')}</h3>
                  <Switch
                    onChange={function () {
                      setIsActive((prevValue) => !prevValue);
                    }}
                    checked={isActive ?? false}
                    onColor={'#2a85ff'}
                  />
                </FormRow>
              )}
            </FormRow>
            <FormRow>
              <Input
                {...register('label')}
                error={errors.label?.message ?? ''}
                label={t('common.label')}
                type='text'
                value={label ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('label', e.toString());
                  setLabel(e.toString());
                }}
              />
              <Input
                {...register('externalReference')}
                error={errors.externalReference?.message ?? ''}
                label={t('common.yourReference')}
                type='text'
                value={externalReference ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setExternalReference(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('tvaNumber')}
                error={errors.tvaNumber?.message ?? ''}
                label={t('common.tvaNumber')}
                type='text'
                value={tvaNumber ?? ''}
                placeholder='FRXX999999999'
                disabled={false}
                onChange={function (e): void {
                  setTvaNumber(e.toString());
                }}
              />
              <SelectCustomer
                titleSize='normal'
                register={register}
                registerName='client'
                setValue={setValue}
                error={errors.client?.value?.message ?? errors.client?.label?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: SelectCustomerOption) => {
                  if (e && e.value) {
                    setValue('client', {
                      value: e?.value,
                      label: e?.label,
                    });

                    setSaveBuildingSite((prevValue) => {
                      return { ...prevValue, customerId: e?.value };
                    });

                    setCustomerIsExemptFromRepTax(e.data.isExemptFromRepTax ?? false);
                  } else {
                    setValue('client', {
                      value: '',
                      label: '',
                    });

                    setSaveBuildingSite((prevValue) => {
                      return { ...prevValue, customerId: undefined };
                    });

                    setCustomerIsExemptFromRepTax(false);
                  }
                }}
                forceSelectedOption={customerOption}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('streetAddress')}
                error={errors.streetAddress?.message ?? ''}
                label={t('common.address')}
                type='text'
                value={streetAddress ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('streetAddress', e.toString());
                  setStreetAddress(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <SelectLocality
                register={register}
                registerName='locality'
                setValue={setValue}
                error={errors.locality?.value?.message ?? errors.locality?.label?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: SelectCountryOption) => {
                  setPostalCode(e?.data?.codesPostaux[0] === 'INCONNU' ? postalCode : e?.data?.codesPostaux[0]);
                  setSaveBuildingSite((prevValue) => {
                    return { ...prevValue, locality: e?.data?.nom, inseeCode: e?.data?.code };
                  });
                }}
                forceSelectedOption={localityOption}
                titleSize='normal'
              />
              <Input
                {...register('postalCode')}
                error={errors.postalCode?.message ?? ''}
                label={t('common.postalCode')}
                type='text'
                value={postalCode ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setPostalCode(e.toString());
                }}
              />
              <SelectCountry
                register={register}
                registerName='country'
                setValue={setValue}
                error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
                isSelectable={true}
                selectedOptionChanged={(e: SelectCountryOption) => {
                  setSaveBuildingSite((prevValue) => {
                    return { ...prevValue, country: e?.value };
                  });
                }}
                forceSelectedOption={countryOption}
                titleSize='normal'
              />
            </FormRow>
          </div>
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.repTax')} type='title1' />
            </FormRow>
            <FormRow>
              {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
              <div
                className='panelInput'
                title={
                  customerIsExemptFromRepTax
                    ? t('common.customerIsExemptFromRepTaxDesc')
                    : t('common.customerIsSubjectToRepTaxDesc')
                }
              >
                <Switch
                  className='base2'
                  type='text'
                  checked={customerIsExemptFromRepTax}
                  onColor={'#2a85ff'}
                  onChange={() => {
                    return undefined;
                  }}
                  disabled={true}
                />
                <h3 className='base2' style={{ margin: '0px 10px' }}>
                  {t('common.customerIsExemptFromRepTax')}
                </h3>
              </div>
              {!customerIsExemptFromRepTax && (
                <div className='panelInput' title={t('common.buildingSiteIsExemptFromRepTaxDesc')}>
                  <Switch
                    className='base2'
                    type='text'
                    checked={isExemptFromRepTax}
                    onChange={(checked: boolean) => setIsExemptFromRepTax(checked)}
                    onColor={'#2a85ff'}
                  />
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.buildingSiteIsExemptFromRepTax')}
                  </h3>
                </div>
              )}
              {customerIsExemptFromRepTax && (
                <div className='panelInput' title={t('common.buildingSiteIsExemptFromRepTaxDesc')}>
                  <Switch
                    className='base2'
                    type='text'
                    checked={!isSubjectToRepTax}
                    onChange={(checked: boolean) => setIsSubjectToRepTax(!checked)}
                    onColor={'#2a85ff'}
                  />
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.buildingSiteIsExemptFromRepTax')}
                  </h3>
                </div>
              )}
            </FormRow>
            {isExemptFromRepTax && (
              <FormRow alignVert='center'>
                {mode !== 'add' && (
                  // hide document management when adding because we don't have the entity id yet
                  <ManageDocument
                    label={t('common.exemptionDocument')}
                    hoverDescription={t('common.exemptionDocumentDesc')}
                    docType={'RepBuildingSiteExemption'}
                    entityType='building_site'
                    entityId={buildingSiteId ?? ''}
                    onAddDocument={() => {
                      return undefined;
                    }}
                  />
                )}
                <div
                  title={t('common.clickHereForRepExemptionTemplateDesc')}
                  style={{
                    margin: '10px 20px',
                  }}
                >
                  <a
                    href={'http://download.synaxe.com/tmp/modele%2Dattestation%2Dexoneration%2Dchantier%2Epdf'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('common.clickHereForRepExemptionTemplate')}
                  </a>
                </div>
              </FormRow>
            )}
          </div>
          {mode === 'add' && (
            <div className='form-section'>
              <FormRow>
                <Title label={t('common.contact')} type='title1' />
              </FormRow>
              <FormRow>
                <Input
                  {...register('lastname')}
                  error={errors?.lastname?.message ?? ''}
                  label={t('common.lastname')}
                  type='text'
                  value={lastname ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('lastname', e.toString());
                    setLastname(e.toString());
                  }}
                />
                <Input
                  {...register('firstname')}
                  error={errors?.firstname?.message ?? ''}
                  label={t('common.firstname')}
                  type='text'
                  value={firstname ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('firstname', e.toString());
                    setFirstname(e.toString());
                  }}
                />
              </FormRow>
              <FormRow>
                <Input
                  {...register('telephone')}
                  error={errors?.telephone?.message ?? ''}
                  label={t('common.telephone')}
                  type='text'
                  value={telephone ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('telephone', e.toString());
                    setTelephone(e.toString());
                  }}
                />
                <Input
                  {...register('email')}
                  error={errors?.email?.message ?? ''}
                  label={t('common.email')}
                  type='email'
                  value={email ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('email', e.toString());
                    setEmail(e.toString());
                  }}
                />
              </FormRow>
            </div>
          )}
          <div className='form-section'>
            <BuildDataCustom
              dataCustomType={'BuildingSite'}
              dataCustom={buildingSiteDataCustom}
              dataCustomUpdated={(data: DataCustomItem) => {
                setSaveBuildingSite((prevValue) => {
                  return { ...prevValue, dataCustom: data };
                });
              }}
            />
          </div>
          <FixBar>
            <Button label={t('common.return')} style='white' onClick={onCancel} />
            <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
          </FixBar>
        </CardDetail>
      </form>
    );
  },
);

GeneralBuildingSite.displayName = 'GeneralBuildingSite';
export default GeneralBuildingSite;
