import AdditionalDoc from './AdditionalDoc';
import { AdditionalDocType } from './AdditionalDocType';
import Product from './Product';
import Site from './Site';
import Trip from './Trip';

export default class Zone {
  id?: string;
  isActive?: boolean;
  createdAt?: Date;
  lastModificationAt?: Date;
  createdBy?: string;
  lastModificationBy?: string;
  label?: string;
  externalReference?: string;
  currentOccupation?: number;
  maxOccupation?: number;
  latitude?: number;
  longitude?: number;
  isDefaultOverloadUnloadingZone?: boolean;
  apiKey?: string;
  zoneType?: ZoneType;
  dataCustom?: string;
  siteId?: string;
  site?: Site;
  additionalDocs?: AdditionalDoc[];
  products?: Product[];
  trips?: Trip[];
  childZones?: { id: string; label: string }[];
}

export interface GetZone {
  createdAt: Date;
  createdBy?: string;
  currentOccupation: number;
  externalReference: string;
  id: string;
  isActive: boolean;
  zoneType?: ZoneType;
  label: string;
  lastModificationAt: Date;
  lastModificationBy?: string;
  latitude?: number;
  longitude?: number;
  maxOccupation: number;
  productCounter: number;
  isSiteExit: boolean;
  hasDoubleValidation: boolean;
  childZones?: { id: string; label: string }[];
  parentZoneId?: string;
  products: Product[];
  additionalDocs?: [
    {
      id: string;
      autoLinkToTrip: boolean;
      autoLinkAtTripStart: boolean;
      autoLinkAtTripEnd: boolean;
      displayAtTripStart: boolean;
      displayAtTripEnd: boolean;
      origin?: string;
      label: string;
      additionalDocType: AdditionalDocType;
      url: string;
    },
  ];
}

export interface ZoneSave {
  label?: string;
  zoneType?: string;
  externalReference?: string;
  latitude?: number;
  longitude?: number;
  maxOccupation?: number | null;
  products?: string[];
  isSiteExit?: boolean;
  childZonesId?: string[];
  isActive?: boolean;
}

export enum ZoneType {
  PROCESS = 'process',
  WAITING = 'waiting',
  TRANSIT = 'transit',
}
export interface GetZoneParams {
  page: number;
  limit: number;
  sortBy?: string;
  orderBy?: string;
}
export interface GetZonePayload {
  isActive?: boolean;
  zoneType?: string;
  label?: string;
  externalReference?: string;
}
