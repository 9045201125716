import { createInstance } from '../Config';
import { GetZone, GetZoneParams, GetZonePayload } from '../models/Zone';

const instance = createInstance({ isExtranet: true });

export const fetchZones = async ({
  accessToken,
  orgid,
  params,
  payload,
}: {
  accessToken: string;
  orgid: string;
  params: GetZoneParams;
  payload: GetZonePayload;
}): Promise<{ items: GetZone[]; total: number }> => {
  const response = await instance.post(
    `${orgid}/zone/get`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params,
    },
  );

  return { items: response.data.content.items, total: response.data.meta.total };
};
