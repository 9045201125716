import { useEffect, useState } from 'react';
import { SelectCustomerOption } from '../components/forms/SelectCustomer';
import useAuthGuard from './useAuthGuard';
import { GetCustomer, GetCustomerParams } from '../models/Customer';
import { fetchCustomers } from '../services/customer';
import axios from 'axios';
import { handleApiError } from '../utils/apiErrorHandler';
import { useNavigate } from 'react-router-dom';
import useToast from './useToast';

const useSearchCustomer = () => {
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();
  const { addToast } = useToast();

  const [searchOptions, setSearchOptions] = useState<SelectCustomerOption[]>([]);
  const [filterText, setFilterText] = useState('');
  const fetchByQuery = async (query: string) => {
    setFilterText(query);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => (filterText.length > 0 ? fetch(filterText) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [filterText]);

  const fetch = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      if (filterText.length <= 0) {
        setSearchOptions([]);
        return;
      }

      const params: GetCustomerParams = {
        page: 1,
        limit: 200,
        onlyActive: true,
        search: filterText,
      };

      const { items } = await fetchCustomers({
        accessToken: token,
        orgid,
        params,
      });

      const options = generateCustomerOptions(items);
      setSearchOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  const generateCustomerOptions = (customers: GetCustomer[]) => {
    const options =
      customers.map((customer) => ({
        value: customer.id,
        label: customer.label ?? '',
        data: customer,
      })) || [];

    return options;
  };

  return {
    filterText,
    fetchByQuery,
    searchOptions,
  };
};

export default useSearchCustomer;
