import { useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

export const useTableName = () => {
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const tableName = pathSegments.reverse().find((segment) => !uuidValidate(segment)) || '';

  return tableName;
};
