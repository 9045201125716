import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CategoryScale, ChartData, Chart as ChartJS } from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import useAuthGuard from '../../../hooks/useAuthGuard';
import useToast from '../../../hooks/useToast';
import { GetStats, Stats } from '../../../models/Stat';
import { fetchStats } from '../../../services/stats';
import axios from 'axios';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { useTranslation } from 'react-i18next';
import { currentCurrency } from '../../../utils/priceUtils';
import StringUtils from '../../../utils/stringUtils';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';

const StatsPage = React.memo(() => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  // #endregion generic

  // #region specific
  ChartJS.register(CategoryScale);
  const [stats, setStats] = useState<GetStats>();
  const [chartDay, setChartDay] = useState<ChartData<'bar'>>();
  const [chartMonth, setChartMonth] = useState<ChartData<'bar'>>();
  const [chartYear, setChartYear] = useState<ChartData<'bar'>>();
  const [chartLabels, setChartLabels] = useState<string[]>([]);

  useEffect(() => {
    const fetchCurrentStats = async () => {
      if (!token || !orgid) {
        return;
      }

      try {
        setIsLoading(true);

        const fetchedStats = await fetchStats({
          accessToken: token,
          orgid,
        });
        setStats(fetchedStats);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        if (isError) {
          handleApiError({ error, addToast, history });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentStats();
  }, []);

  useEffect(() => {
    if (stats) {
      // 1) Extraire les "labels" et les séries de données
      const labels = Object.keys(stats);
      setChartLabels(labels);
      // -> ["today", "yesterday", "currentMonth", "previousMonth", "currentYear", "previousYear"]

      const chartLabels = [
        t('common.revenue'),
        t('common.tonnage'),
        t('common.revenueLoad'),
        t('common.tonnageLoad'),
        t('common.revenueUnload'),
        t('common.tonnageUnload'),
      ];
      const chartDayData: ChartData<'bar'> = {
        labels: chartLabels,
        datasets: [
          {
            label: t('common.yesterday'),
            data: Object.entries(stats.yesterday ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: t('common.today'),
            data: Object.entries(stats.today ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
        ],
      };
      const chartMonthData: ChartData<'bar'> = {
        labels: chartLabels,
        datasets: [
          {
            label: t('common.previousMonth'),
            data: Object.entries(stats.previousMonth ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: t('common.currentMonth'),
            data: Object.entries(stats.currentMonth ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
        ],
      };
      const chartYearData: ChartData<'bar'> = {
        labels: chartLabels,
        datasets: [
          {
            label: t('common.previousYear'),
            data: Object.entries(stats.previousYear ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
          },
          {
            label: t('common.currentYear'),
            data: Object.entries(stats.currentYear ?? {}).map(([key, value]) => value),
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
        ],
      };
      setChartDay(chartDayData);
      setChartMonth(chartMonthData);
      setChartYear(chartYearData);
    }
  }, [stats]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as 'top' | 'bottom',
      },
      title: {
        display: true,
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };

  const content = (
    <CardDetail>
      {!isLoading && (
        <>
          {/* Tableau d'affichage rapide de toutes les valeurs */}
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              marginBottom: '2rem',
              color: '#90A0B7',
            }}
          >
            <thead>
              <tr>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Période</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueDesc')}>
                  {t('common.revenue')}
                </th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Tonnage</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueUnloadDesc')}>
                  {t('common.revenueUnload')}
                </th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Tonnage Entrées</th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueLoadDesc')}>
                  {t('common.revenueLoad')}
                </th>
                <th style={{ border: '1px solid #ccc', padding: '8px' }}>Tonnage Sorties</th>
              </tr>
            </thead>
            <tbody>
              {chartLabels.map((key) => {
                const stat = (stats as never)[key] as Stats;
                return (
                  <tr key={key}>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{t('common.' + key)}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueDesc')}>
                      {StringUtils.currencyFormat(stat.revenue, currentCurrency)}
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                      {StringUtils.numberFormat(Math.round(stat.tonnage)) + ' T'}
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueUnloadDesc')}>
                      {StringUtils.currencyFormat(stat.revenueUnload, currentCurrency)}
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                      {StringUtils.numberFormat(Math.round(stat.tonnageUnload)) + ' T'}
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }} title={t('common.revenueLoadDesc')}>
                      {StringUtils.currencyFormat(stat.revenueLoad, currentCurrency)}
                    </td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                      {StringUtils.numberFormat(Math.round(stat.tonnageLoad)) + ' T'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Graphique Bar Chart */}
          <FormRow>
            {chartDay && (
              <div style={{ position: 'relative', width: '50%', margin: '0 auto' }}>
                <Bar
                  data={chartDay}
                  options={{
                    ...options,
                    plugins: { ...options.plugins, title: { display: true, text: 'Comparaison Journalière' } },
                  }}
                />
              </div>
            )}
            {chartMonth && (
              <div style={{ position: 'relative', width: '50%', margin: '0 auto' }}>
                <Bar
                  data={chartMonth}
                  options={{
                    ...options,
                    plugins: { ...options.plugins, title: { display: true, text: 'Comparaison Mensuelle' } },
                  }}
                />
              </div>
            )}
          </FormRow>
          {chartYear && (
            <div style={{ position: 'relative', width: '80%', margin: '0 auto' }}>
              <Bar
                data={chartYear}
                options={{
                  ...options,
                  plugins: { ...options.plugins, title: { display: true, text: 'Comparaison Annuelle' } },
                }}
              />
            </div>
          )}
        </>
      )}
    </CardDetail>
  );

  return (
    <div className='main-container'>
      <Menu selectedPage='products'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
      <div className='right detail-container'>
        <Head title={t('common.statistics')} back={true} />
        {content}
      </div>
    </div>
  );
});

StatsPage.displayName = 'StatsPage';
export default StatsPage;
