import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type QueryParamsSetValues = {
  [key: string]: Set<string>;
};

export const useQuery = () => {
  const { search, hash } = useLocation();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryKeys = useMemo(() => Array.from(queryParams.keys()), [search]);

  const querySetValues: QueryParamsSetValues = useMemo(() => {
    const setValues: QueryParamsSetValues = {};
    queryParams.forEach((value, key) => {
      if (!setValues[key]) {
        setValues[key] = new Set();
      }
      const values = value.split(',');
      values.forEach((value) => {
        setValues[key].add(value);
      });
    });
    return setValues;
  }, [search]);

  return { queryParams, queryKeys, querySetValues, hash };
};
