import React, { ForwardedRef } from 'react';
import { cn } from '../../../../lib/utils';
import { InputUI } from './InputUI';
import { Label } from '../../atoms/label/Label';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconPosition?: 'left' | 'right';
  labelChildren?: React.ReactNode;
  direction?: 'row' | 'column';
  className?: string;
  containerClassName?: string;
  disableStyleComboboxInput?: boolean;
}

const Input = React.forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    Icon,
    iconPosition,
    labelChildren,
    direction,
    className,
    containerClassName,
    disableStyleComboboxInput,
    ...rest
  } = props;

  const iconClasses = cn(
    'absolute h-6 w-6',
    iconPosition === 'left' ? 'left-2' : 'right-2',
    'top-1/2 transform -translate-y-1/2',
  );

  const containerClasses = cn(
    'flex',
    { 'flex-row': direction === 'row', 'flex-col': direction === 'column', 'items-center': direction === 'row' },
    'gap-5',
    containerClassName,
  );

  const inputClasses = cn({ 'pl-9': iconPosition === 'left', 'pr-9': iconPosition === 'right' }, className);

  return (
    <div className={containerClasses}>
      {labelChildren && <Label>{labelChildren}</Label>}
      <div className='relative flex w-full'>
        <InputUI {...rest} className={inputClasses} ref={ref} disableStyleComboboxInput={disableStyleComboboxInput} />
        {Icon && (
          <div className={iconClasses}>
            <Icon />
          </div>
        )}
      </div>
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
