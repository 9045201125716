import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from '../../src/i18n';

const Lang = () => {
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;

  const { i18n } = useTranslation();
  const [lang, setLang] = useState<Language>(i18n.language as Language);

  const changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;

    switch (language) {
      case Language.EN:
        localStorage.setItem('language', Language.EN);
        setLang(Language.EN);
        i18n.changeLanguage(Language.EN);
        break;
      case Language.FR:
      default:
        setLang(Language.FR);
        localStorage.setItem('language', Language.FR);
        i18n.changeLanguage(Language.FR);
        break;
    }
  };

  return (
    <div>
      <div>
        <select name='language' onChange={changeLanguage} defaultValue={currentLanguage}>
          <option value={Language.FR}>FR</option>
          <option value={Language.EN}>EN</option>
        </select>
      </div>
    </div>
  );
};

export default Lang;
