import { WEEKDAYS } from '../constants/constants';
import { AnyObject, Weekday, WithId } from '../types';

export const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === 'number';
};

export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

export const isObject = (value: unknown): value is object => {
  return typeof value === 'object';
};

export const isWeekday = (value: unknown): value is Weekday => {
  return WEEKDAYS.includes(value as Weekday);
};

export const isArrayWithId = <T>(array: unknown[]): array is Array<{ id: string } & T> => {
  return Array.isArray(array) && array.every((item) => typeof item === 'object' && item !== null && 'id' in item);
};

export const isArrayWithKey = <T>(
  array: unknown[],
  key: keyof AnyObject,
): array is Array<{ [K in keyof AnyObject]: string } & T> => {
  return Array.isArray(array) && array.every((item) => typeof item === 'object' && item !== null && key in item);
};

export const hasId = <T>(row: T): row is T & WithId => {
  return (row as WithId).id !== undefined;
};

export const hasKey = <T extends object>(obj: unknown, key: keyof T): obj is T => {
  return typeof obj === 'object' && obj !== null && key in obj;
};

export const isKeyOf = <T extends object>(key: unknown, obj: T): key is keyof T => {
  return (typeof key === 'string' || typeof key === 'number' || typeof key === 'symbol') && key in obj;
};
