import React, { useEffect, useState } from 'react';
import './Account.scss';
import '../../styles/table.scss';
import '../../styles/popup.scss';
import axios from 'axios';
import Menu from '../Menu';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ToastTypes } from '../../models/ToastTypes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchAccount, postNewPassword } from '../../services/auth';
import { handleApiError } from '../../utils/apiErrorHandler';
import { Buffer } from 'buffer';
import useToast from '../../hooks/useToast';
import useAuthGuard from '../../hooks/useAuthGuard';
import { Input } from '../ui/atoms/input/Input';
import { Label } from '@radix-ui/react-label';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/molecules/Card';
import { Button } from '../ui/atoms/button/Button';

interface IFormInputs {
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
}

const Account = React.memo(() => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { token } = useAuthGuard();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const regExPassword = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required('Le password est obligatoire')
      .matches(regExPassword, '8-20 Caractères, une majuscule, une minuscule, un chiffre'),
    confirmPassword: yup
      .string()
      .required('Le password est obligatoire')
      .oneOf([yup.ref('password')], 'Les mots de passe ne correspondent pas'),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const updateFirstname = (firstname: string) => {
    setValue('firstname', firstname);
    setFirstname(firstname);
    setErrorMessage('');
  };

  const updateLastname = (lastname: string) => {
    setValue('lastname', lastname);
    setLastname(lastname);
    setErrorMessage('');
  };

  const updatePassword = (password: string) => {
    setValue('password', password);
    setNewPassword(password);
    setErrorMessage('');
  };

  const updateConfirmPassword = (confirmPassword: string) => {
    setValue('confirmPassword', confirmPassword);
    setConfirmPassword(confirmPassword);
    setErrorMessage('');
  };

  const onSubmitPassword = async () => {
    try {
      setIsAddingLoading(true);

      if (!token) {
        return;
      }
      await postNewPassword({
        accessToken: token,
        newPassword: Buffer.from(newPassword).toString('base64'),
        passwordConfirmation: Buffer.from(confirmPassword).toString('base64'),
      });

      setIsAddingLoading(false);
      addToast(t('common.passwordUpdated'), ToastTypes.success);
      setConfirmPassword('');
      setNewPassword('');
    } catch (error) {
      setIsAddingLoading(false);
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }

      try {
        const { firstname, lastname } = await fetchAccount(token);
        setFirstname(firstname);
        setLastname(lastname);
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      }
    };

    fetchData();
  }, []);

  return (
    <div className='updatePassword'>
      <Menu selectedPage='account'></Menu>

      <div className={'right detail-container'}>
        <Card className='w-96'>
          <CardHeader>
            <CardTitle>{t('common.myAccount')}</CardTitle>
          </CardHeader>
          <CardContent>
            <Label className='text-xs text-neutral-400'>{t('common.lastname')}</Label>
            <Input
              readOnly={true}
              type='text'
              name='lastname'
              value={lastname}
              placeholder='Nom'
              onChange={(e) => updateLastname(e?.target.value.toString())}
            />
            <p className='validator-error'>{errors.lastname?.message}</p>
            <Label className='text-xs text-neutral-400'>{t('common.firstname')}</Label>
            <Input
              readOnly={true}
              type='text'
              name='firstname'
              value={firstname}
              placeholder='Prénom'
              onChange={(e) => updateFirstname(e?.target.value.toString())}
            />
            <p className='validator-error'>{errors.firstname?.message}</p>
          </CardContent>
          <CardHeader>
            <CardTitle>{t('common.editPassword')}</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmitPassword)}>
              <Label className='text-xs text-neutral-400'>{t('common.newPassword')}</Label>
              <Input
                type='password'
                name='newPassword'
                value={newPassword}
                placeholder={t('common.newPassword')}
                onChange={(e) => updatePassword(e?.target.value.toString())}
              />
              <p className='validator-error'>{errors.password?.message}</p>
              <Label className='text-xs text-neutral-400'>{t('common.confirmNewPassword')}</Label>
              <Input
                type='password'
                name='confirmPassword'
                value={confirmPassword}
                placeholder={t('common.confirmNewPassword')}
                onChange={(e) => updateConfirmPassword(e?.target.value.toString())}
              />
              <p className='validator-error'>{errors.confirmPassword?.message}</p>

              <div>{errorMessage}</div>
              <CardFooter className='items-start'>
                <Button onClick={handleSubmit(onSubmitPassword)}>
                  {!isAddingLoading ? t('common.save') : t('common.saving')}
                </Button>
              </CardFooter>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
});

Account.displayName = 'Account';
export default Account;
