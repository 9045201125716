import { useEffect, useRef } from 'react';
import { useTable } from '../context/TableProvider';
import { Table } from '@tanstack/react-table';
import { isDeepEqual } from '../utils/function';

const useUpdateTableInstance = <TData>(table: Table<TData> | null) => {
  const { dispatch } = useTable();
  const previousSelectedRowsRef = useRef<unknown>(null);

  useEffect(() => {
    if (table !== null) {
      const currentSelectedRows = table.getSelectedRowModel().rows;

      if (!isDeepEqual(previousSelectedRowsRef.current, currentSelectedRows)) {
        dispatch({ type: 'SET_TABLE_INSTANCE', payload: table as Table<unknown> });
        previousSelectedRowsRef.current = currentSelectedRows;
      }
    }
  }, [table, dispatch, table?.getState().rowSelection]);
};

export default useUpdateTableInstance;
