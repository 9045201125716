import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { NA } from '../constants/constants';
import { AnyObject } from '../types';
import { isString } from './typeguards';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Paris');

export const convertRangeToTime = (range: string) => {
  return `${range.padStart(2, '0')}:00`;
};

export const formatDate = (date?: string | null): string => {
  if (!date) return NA;

  return dayjs(date).format('DD/MM/YYYY');
};

export const formatISODateTime = (ISODate?: string | null) => {
  if (!ISODate) {
    return {
      date: NA,
      time: NA,
    };
  }

  const formattedDate = dayjs(ISODate).tz('Europe/Paris').format('DD/MM/YYYY');
  const formattedTime = dayjs(ISODate).tz('Europe/Paris').format('HH:mm');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const isDeepEqual = (object1: unknown, object2: unknown): boolean => {
  if (object1 === object2) {
    return true;
  }

  if (object1 instanceof Set && object2 instanceof Set) {
    return isSetEqual(object1, object2);
  }

  if (!isObject(object1) || !isObject(object2)) {
    return false;
  }

  const objKeys1 = Object.keys(object1 as AnyObject);
  const objKeys2 = Object.keys(object2 as AnyObject);

  if (objKeys1.length !== objKeys2.length) return false;

  for (const key of objKeys1) {
    if (!isDeepEqual((object1 as AnyObject)[key], (object2 as AnyObject)[key])) {
      return false;
    }
  }

  return true;
};

const isObject = (object: unknown): object is AnyObject => {
  return typeof object === 'object' && object !== null;
};

const isSetEqual = (set1: Set<unknown>, set2: Set<unknown>): boolean => {
  if (set1.size !== set2.size) {
    return false;
  }
  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
};

export const isEmptyObject = (object: AnyObject): boolean => {
  return Object.keys(object).length === 0;
};

export const getValueAsString = (val: unknown): string => {
  return isString(val) ? val : '';
};

export const removeDuplicatesByKey = <T, K extends keyof T>(array: T[], key: K): T[] => {
  const seenKeys = new Set<T[K]>();

  return array.filter((item) => {
    const keyValue = item[key];
    if (seenKeys.has(keyValue)) {
      return false;
    }

    seenKeys.add(keyValue);
    return true;
  });
};

export const storeJSONInLocalStorage = (json: { data: AnyObject[] }, key: string) => {
  const data = JSON.stringify(json);
  localStorage.setItem(key, data);
};

export const cleanAndConvertToNumber = (string: string) => {
  const cleaned = string.replace(/[^\d.-]/g, '');
  return Number(cleaned);
};

export const extractFormFieldValues = <T extends FieldValues>(form: UseFormReturn<T>, fieldName: string): string[] => {
  return [form.getValues(fieldName as Path<T>) ?? ''].filter(Boolean);
};

export const hasMaxDecimals = (value: number, maxDecimals: number): boolean => {
  if (value === undefined || value === null) {
    return true;
  }

  const factor = Math.pow(10, maxDecimals);
  const roundedValue = Number((Math.abs(value) * factor).toFixed(maxDecimals));
  return Number.isInteger(roundedValue);
};

export const hasMaxDigits = (value: number | undefined | null, maxDigits: number): boolean => {
  if (value === undefined || value === null) {
    return true;
  }

  const numberOfDigits = value.toString().replace('.', '').length;
  return numberOfDigits <= maxDigits;
};

export const filterObjectKeys = <T extends Record<string, unknown>>({
  keysToKeep,
  obj,
}: {
  obj: T;
  keysToKeep: (keyof T)[];
}): Partial<T> => {
  return (Object.keys(obj) as Array<keyof T>)
    .filter((key) => keysToKeep.includes(key))
    .reduce<Partial<T>>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};
