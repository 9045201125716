import React, { useEffect, useState } from 'react';
import './Login.scss';
import '../../index.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { ReactComponent as Logo } from '../../assets/svg/dune-logo.svg';
import SelectWorkspace from '../SelectWorkspace';
import Roles from '../../utils/Roles';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { loadStaticData } from '../../store/staticData-slice';
import { fetchUserData, signin } from '../../services/auth';
import validator from 'validator';
import Alert from '../ui/molecules/alert/Alert';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/molecules/Card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/molecules/Form';
import { Input } from '../ui/atoms/input/Input';
import { Button } from '../ui/atoms/button/Button';
import useElementSize from '../../hooks/useElementSize';
import { Spinner } from '../ui/atoms/Spinner';
import LoginLayout from '../layout/LoginLayout';
import { Label } from '../ui/atoms/label/Label';
import { Language } from '../../i18n';

const loginSchema = yup.object().shape({
  username: yup
    .string()
    .test('is-email', t('login.invalidEmail'), (value) => validator.isEmail(value || ''))
    .required(t('login.requiredEmail')),
  password: yup.string().required(t('login.requiredPassword')),
});

export type Credentials = yup.InferType<typeof loginSchema>;

const Login = () => {
  const { t } = useTranslation();
  Language.FR; // Nécessaire pour initialiser la langue ?? // TODO trouver pourquoi comportement différent de AC

  const form = useForm<Credentials>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const isSubmitable = form.formState.isValid;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const [isSelectingOrga, setIsSelectingOrga] = useState(false);

  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // The `useDispatch` hook with AppDispatch type to call async thunk actions

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Credentials>({
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const chooseWorkspace = () => {
    history('/trips');
  };

  const onSubmit = async (credentials: Credentials) => {
    const credentialsBase64 = {
      username: credentials.username,
      password: Buffer.from(credentials.password).toString('base64'),
    };

    try {
      const { accessToken } = await signin(credentialsBase64);
      localStorage.setItem('token', accessToken);
      localStorage.setItem('email', credentialsBase64.username);
      getScopes();
    } catch (error) {
      const is401Error = axios.isAxiosError(error) && error.response && error.response.status === 401;
      is401Error ? setErrorMessage(t('login.IncorrectCredentials')) : setErrorMessage(t('login.ErrorMessage'));
    }
  };

  const getScopes = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setErrorMessage(t('login.ErrorMessage'));
      return;
    }

    try {
      const { workspaces, uuid } = await fetchUserData(token);
      const savedWorkspaces = Roles.saveWorkspaces(workspaces);
      Roles.saveUser(uuid);

      if (savedWorkspaces.length > 1) {
        setIsSelectingOrga(true);
        return;
      }

      Roles.setLocalStorage(workspaces[0]);
      dispatch(loadStaticData()); // Load static data once authenticated (called at refresh by APP as well)
      history('/trips');
    } catch (error) {
      const is401Error = axios.isAxiosError(error) && error.response && error.response.status === 401;
      is401Error ? setErrorMessage(t('login.IncorrectCredentials')) : setErrorMessage(t('login.ErrorMessage'));
    }
  };

  const { elementRef: buttonRef, size: buttonSize } = useElementSize<HTMLButtonElement>(!isLoading);

  return (
    <LoginLayout>
      {!isSelectingOrga ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Card className='w-96'>
              <CardHeader className='items-center'>
                <Logo />
                <CardTitle>{t('login.welcomeMessage')}</CardTitle>
              </CardHeader>

              <CardContent>
                <FormField
                  control={form.control}
                  name='username'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('login.email')}</FormLabel>
                      <FormControl>
                        <Input {...field} type='email' placeholder='exemple@email.com' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='password'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('login.password')}</FormLabel>
                      <FormControl>
                        <Input {...field} type='password' placeholder={t('login.userPassword')} />
                      </FormControl>
                      <Link to={`/forgot-password?email=${form.getValues('username')}`} className='text-sm underline'>
                        {t('login.forgetPassword')}
                      </Link>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
              <CardFooter className='flex-col items-start'>
                {isLoading ? (
                  <Button
                    disabled={!isSubmitable}
                    style={{ width: `${buttonSize.width}px`, height: `${buttonSize.height}px` }}
                  >
                    <Spinner variant='secondary' />
                  </Button>
                ) : (
                  <Button ref={buttonRef}>{t('login.connexion')}</Button>
                )}
                {errorMessage && !isLoading ? (
                  <Alert className='mt-4' variant='destructive' description={errorMessage} />
                ) : null}
              </CardFooter>
            </Card>
          </form>
        </Form>
      ) : (
        <>
          <div>
            <Card className='w-96'>
              <CardHeader>
                <CardTitle>{t('login.chooseWorkspace')}</CardTitle>
              </CardHeader>
              <CardContent>
                <Label className='text-xs text-neutral-400'>
                  {form.getValues('username')} {t('login.workspaceSelection')}
                </Label>

                <SelectWorkspace />
              </CardContent>
              <CardFooter className='flex-col items-start'>
                <Button onClick={chooseWorkspace}>{t('login.access')}</Button>
              </CardFooter>
            </Card>
          </div>
        </>
      )}
    </LoginLayout>
  );
};

export default Login;
