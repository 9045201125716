import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Check, ChevronsUpDown } from 'lucide-react';
import { FormControl, FormItem, FormLabel, FormMessage } from './Form';
import { Popover, PopoverContent, PopoverTrigger } from '../atoms/Popover';
import { Button } from '../atoms/button/Button';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '../atoms/Command';
import { cn } from '../../../lib/utils';
import { Input } from '../atoms/input/Input';
import { Option } from '../../../types';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { t } from 'i18next';

type FormComboboxProps = {
  name: string;
  label: string;
  selectPlaceholder: string;
  options: Option[];
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
  withInput?: boolean;
  searchPlaceholder?: string;
  maxItems?: number;
  required?: boolean;
  align?: 'start' | 'end';
  disabled?: boolean;
  popoverContentWidth?: string;
  stopPropagation?: boolean;
  onSelectionChange?: (value: string) => void;
  itemIdsToComment?: string[];
  comment?: string;
};

const FormCombobox: React.FC<FormComboboxProps> = ({
  name,
  label,
  selectPlaceholder,
  options,
  searchTerm,
  setSearchTerm,
  withInput = true,
  searchPlaceholder,
  maxItems = 10,
  required = false,
  align,
  disabled,
  popoverContentWidth,
  stopPropagation,
  onSelectionChange,
  itemIdsToComment,
  comment,
}) => {
  const [open, setOpen] = useState(false);
  const { control, getValues, setValue, trigger } = useFormContext();

  const handleOnSelectCombobox = async (value: string, fieldName: string) => {
    onSelectionChange?.(value);
    const currentValue = getValues(fieldName);

    setValue(fieldName, currentValue === value ? '' : value);
    await trigger(fieldName);
    setOpen(false);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className='flex items-center gap-2 text-base font-normal' required={required}>
            {label}
          </FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant='outline'
                  role='combobox'
                  className='h-10 w-full justify-between disabled:pointer-events-auto disabled:cursor-not-allowed'
                  disabled={disabled}
                  onClick={(e) => (stopPropagation ? e.stopPropagation() : null)}
                >
                  <span className='truncate'>
                    {field.value ? options.find((option) => option.value === field.value)?.label : selectPlaceholder}
                  </span>
                  <ChevronsUpDown className='ml-2 size-4 shrink-0 opacity-50' />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className={`${popoverContentWidth ? popoverContentWidth : 'w-full'} p-0`} align={align}>
              <Command>
                {withInput ? (
                  <Input
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    Icon={SearchIcon}
                    iconPosition='left'
                    onChange={(e) => setSearchTerm?.(e.target.value)}
                    disableStyleComboboxInput
                    onClick={(e) => (stopPropagation ? e.stopPropagation() : null)}
                    className='flex h-10 w-full rounded-md border-b bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50'
                  />
                ) : null}
                <CommandList>
                  {options.length === 0 ? (
                    <CommandEmpty>{t('noResult')}</CommandEmpty>
                  ) : (
                    <CommandGroup>
                      {options.slice(0, maxItems).map((option) => (
                        <CommandItem
                          disabled={option.value === 'searchForMore'}
                          key={option.value}
                          value={option.value}
                          onSelect={() => handleOnSelectCombobox(option.value, field.name)}
                        >
                          <Check
                            className={cn('mr-2 h-4 w-4', field.value === option.value ? 'opacity-100' : 'opacity-0')}
                          />
                          {option.label}
                          {itemIdsToComment?.includes(option.value) ? (
                            <span className='italic'>{`\u00A0- ${comment}`}</span>
                          ) : null}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  )}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default FormCombobox;
