import { useRef, useState, useEffect } from 'react';

const useElementSize = <T extends HTMLElement>(isActive: boolean) => {
  const elementRef = useRef<T>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (elementRef.current && isActive) {
      const { offsetWidth, offsetHeight } = elementRef.current;
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [isActive]);

  return { elementRef, size };
};

export default useElementSize;
