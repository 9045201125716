import React, { useEffect, useState } from 'react';
import '../../../styles/table.scss';
import '../../../styles/popup.scss';
import '../../../index.scss';
import axios from 'axios';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Config from '../../../Config';
import Select, { createFilter } from 'react-select';
import Zone, { GetZone, GetZoneParams, GetZonePayload, ZoneSave } from '../../../models/Zone';
import { customStylesSelectReactPopup } from '../../../styles/custom-styles-select-react';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { fetchZones } from '../../../services/zone';
import { handleApiError } from '../../../utils/apiErrorHandler';

interface AddChildZoneProps {
  isUpdate?: boolean;
  childZones?: { id: string; label: string }[];
  from?: string;
  onsuccess(zoneAdded?: any): void;
  currentZone?: Zone;
  ClosePopup(ChildData?: boolean): void;
}

interface IFormInputs {
  childZone: {
    value: string;
    label: string;
  };
  externalReference: string;
  unit: string;
  label: string;
}

const schema = yup.object().shape({
  childZone: yup.object().shape({
    value: yup.string().required('La zone est obligatoire'),
    label: yup.string().required('La zone est obligatoire'),
  }),
});

export const generateZoneOptions = (zones: GetZone[]) => {
  const options =
    zones.map((zone) => ({
      value: zone.id,
      label: zone.label ?? '',
      type: zone.zoneType,
    })) || [];

  return options;
};

const AddChildZone = React.memo((props: AddChildZoneProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();

  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [selectedOptionZone, setselectedOptionZone] = useState<any>();

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    const timeOutId = setTimeout(async () => (query.length > 0 ? await fetchZonesByQuery(query) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const fetchZonesByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      setIsLoading(true);

      const params: GetZoneParams = {
        page: 1,
        limit: 50,
      };

      const payload: GetZonePayload = {
        label: filterText,
        externalReference: filterText,
      };

      const { items } = await fetchZones({
        accessToken: token,
        orgid,
        params,
        payload,
      });
      const options = generateZoneOptions(items);
      setOptions(options);

      if (items.length === 1) {
        setselectedOptionZone(options[0]);
      }
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const updateChildZone = (item: any) => {
    setValue('childZone', item);
    setselectedOptionZone(item);
  };

  const onSubmit = () => {
    onSubmitCreate();
    return;
  };

  const onClosePopup = () => {
    props.ClosePopup();
  };

  const onSubmitCreate = async () => {
    const baseUrl = '/zone/edit/' + props.currentZone?.id;
    const url = orgid + `${baseUrl}`;

    const currentChildZones = props.currentZone?.childZones?.map((z) => z.id) ?? [];

    const dataToSend: ZoneSave = {
      ...props.currentZone,
      products: props.currentZone?.products?.map((p) => p.id ?? '') ?? [],
      childZonesId: [...currentChildZones, selectedOptionZone?.value],
    };

    axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        props.onsuccess({
          id: selectedOptionZone?.value,
          label: selectedOptionZone?.label,
        });
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const deleteSelectedOption = () => {
    setselectedOptionZone({});
  };

  return (
    <div className='popup'>
      <div className='popup__header'>
        <div>
          <p className='title1SemiBold'>{t('common.addChildZone')}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className='popup-col'>
            {props.childZones?.map((childZone) => {
              return (
                <div className='input__title' key={childZone.id}>
                  <div style={{ marginLeft: 20 }}>{childZone.label}</div>
                </div>
              );
            })}

            <Select
              {...register('childZone')}
              className='popup__select'
              isMulti={false}
              isLoading={isLoading}
              options={options}
              loadingMessage={() => 'Chargement en cours'}
              menuPlacement={'auto'}
              menuPosition={'fixed'}
              noOptionsMessage={() =>
                filterText.length < 1 ? 'Entrez 1 caractère pour lancer une recherche' : 'Pas de résultat'
              }
              styles={customStylesSelectReactPopup('#F4F4F4')}
              placeholder={'Choisir une zone'}
              onFocus={() => deleteSelectedOption()}
              onInputChange={(e) => setQuery(e)}
              isSearchable={true}
              onChange={(input) => {
                updateChildZone(input);
              }}
              value={selectedOptionZone}
              filterOption={createFilter(filterConfig)}
            />

            <div style={{ color: 'black' }}> {selectedOptionZone?.id} </div>

            <p className='validator-error'>{errors.childZone?.label?.message ?? errors.childZone?.value?.message}</p>
          </div>
        </>
        <div className='popup-row-right'>
          <div className='addBtn' onClick={handleSubmit(onSubmit)}>
            <div className='base2'>Ajouter</div>
          </div>
          <div className='cancelBtn' onClick={onClosePopup}>
            <div className='base2'>Annuler</div>
          </div>
        </div>
      </form>
    </div>
  );
});

AddChildZone.displayName = 'AddChildZone';
export default AddChildZone;
