import { useState, useEffect } from 'react';

type ViewportSize = {
  viewportWidth: number;
  viewportHeight: number;
};

export const useViewportSize = (): ViewportSize => {
  const [size, setSize] = useState<ViewportSize>({
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
};
