import DefaultModel from './DefaultModel';
import { Invoice } from './Invoice';
import { t } from 'i18next';

export enum PaymentMode {
  cb = 'cb',
  bankTransfer = 'bank-transfer',
  bankDirectDebit = 'bank-direct-debit',
  bankCheck = 'cheque-bank',
  cash = 'cash',
  paypal = 'paypal',
  creditNote = 'credit-note',
}

export const paymentModeOptions: { value: PaymentMode; label: string }[] = [
  { value: PaymentMode.cb, label: t('paymentMode.cb') },
  { value: PaymentMode.bankTransfer, label: t('paymentMode.bankTransfer') },
  { value: PaymentMode.cash, label: t('paymentMode.cash') },
  { value: PaymentMode.bankDirectDebit, label: t('paymentMode.bankDirectDebit') },
  { value: PaymentMode.bankCheck, label: t('paymentMode.bankCheck') },
  { value: PaymentMode.creditNote, label: t('paymentMode.creditNote') },
];

export default class Payment extends DefaultModel {
  mode?: PaymentMode;
  amount?: number;
  transactionNumber?: string;
  payerName?: string;
  bank?: string;
  InvoicePayment?: InvoicePayment;
  // invoices?: Invoice[];
}
export interface InvoicePayment {
  invoiceId: string;
  paymentId: string;
  createdAt?: Date;
  lastModificationAt?: Date;
  amount: number;
}


export interface PaymentSave {
  mode: PaymentMode;
  amount: number;
  transactionNumber: string;
  payerName: string;
  bank: string;
  invoices?: PaymentSaveInvoice[];
}
export interface PaymentSaveInvoice {
  invoiceId: string;
  amount: number;
  isFullyPaid: boolean;
}