import { AdditionalDocType } from './AdditionalDocType';
import DefaultModel from './DefaultModel';

export enum AdditionalDocEntity {
  AFFILIATE = 'affiliate',
  ALERT = 'alert',
  AUTHORIZED_VEHICLE = 'authorized_vehicle',
  BUILDING_SITE = 'building_site',
  CARRIER = 'carrier',
  CONTACT = 'contact',
  CUSTOMER = 'customer',
  INVOICE = 'invoice',
  ORDER = 'order',
  PROCUREMENT_NOTE = 'procurement_note',
  PRODUCT_TYPE = 'product_type',
  PRODUCT = 'product',
  QUOTATION = 'quotation',
  SITE = 'site',
  TERMINAL = 'terminal',
  TRIP = 'trip',
  USER = 'user',
  VEHICLE_INFO = 'vehicle_info',
  VEHICLE = 'vehicle',
  ZONE = 'zone',
}

export interface AdditionalDocSave {
  fileName?: string;
  additionalDocTypeId?: string;
  autoLinkToTrip?: boolean;
  autoLinkAtTripStart?: boolean;
  autoLinkAtTripEnd?: boolean;
  displayAtTripStart?: boolean;
  displayAtTripEnd?: boolean;
  additionalDocEntity?: string;
  entityId?: string;
  origin?: string;
  fileBody?: string;
}
export interface AdditionalDocRemove {
  additionalDocEntity?: string;
  entityId?: string;
}

export interface GetAdditionalDoc {
  id: string;
  createdAt: Date;
  createdBy?: string;
  lastModificationAt: Date;
  lastModificationBy?: string;
  autoLinkToTrip: boolean;
  autoLinkAtTripStart: boolean;
  autoLinkAtTripEnd: boolean;
  displayAtTripStart: boolean;
  displayAtTripEnd: boolean;
  printAtTripStart: boolean;
  printAtTripEnd: boolean;
  origin?: string;
  label: string;
  url: string;
  additionalDocTypeId: string;
  additionalDocType: AdditionalDocType;
}

export interface GetAdditionalDocParameters {
  additionalDocEntity: string;
  entityId: string;
  additionalDocTypeId?: string;
}

export default class AdditionalDoc extends DefaultModel {
  autoLinkToTrip?: boolean;
  autoLinkAtTripStart?: boolean;
  autoLinkAtTripEnd?: boolean;
  displayAtTripStart?: boolean;
  displayAtTripEnd?: boolean;
  printAtTripStart?: boolean;
  printAtTripEnd?: boolean;
  origin?: string;
  label?: string;
  s3Key?: string;
  s3Bucket?: string;
  additionalDocTypeId?: string;
  additionalDocType?: AdditionalDocType;
}
