import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { TablePaymentInvoice } from './types';
import { adjustColumnsSize } from '../../ui/organisms/table/utils';
import { renderRowCheckbox } from '../../ui/organisms/table/renderUtils';
import { Input } from '../../ui/atoms/input/Input';
import { Label } from '../../ui/atoms/label/Label';
import stringUtils from '../../../utils/stringUtils';
import { currentCurrency } from '../../../utils/priceUtils';
import { Link } from 'react-router-dom';

export const setColumns = ({
  tableWidth,
  savedColumns,
  setAmount,
  setSelected,
}: {
  tableWidth: number;
  savedColumns?: string[];
  setAmount: (id: string, amount: number) => void;
  setSelected: (id: string, checked: boolean) => void;
}): ColumnDef<TablePaymentInvoice>[] => {
  const columns: ColumnDef<TablePaymentInvoice>[] = [
    {
      id: 'id',
      accessorKey: 'id',
      header: '',
      meta: {
        topHeaderRowClass: 'hidden',
        cellClassName: 'hidden',
      },
    },
    {
      id: 'isSelected',
      accessorKey: 'isSelected',
      cell: ({ row }) => {
        return renderRowCheckbox({
          item: {
            ...row,
            toggleSelected: (value) => {
              setSelected(row.getValue('id'), value ?? false);
              row.toggleSelected(value);
            },
          },
          isDisabled: false,
        });
      },
      enableSorting: false,
      enableHiding: false,
      header: '',
      meta: {
        cellClassName: 'justify-start',
        sizePercentage: 0,
      },
    },
    {
      id: 'invoiceNumber',
      accessorKey: 'invoiceNumber',
      header: '',
      meta: {
        topHeaderRowClass: 'justify-start',
        cellClassName: 'justify-start',
        sizePercentage: 30,
      },
      cell: ({ cell }) => (
        <>
          <Link to={`/invoices/${cell.row.getValue('id')}/update`} target='_blank' className='underline'>
            {t('common.invoice') + ' ' + cell.row.getValue('invoiceNumber')}
          </Link>
        </>
      ),
    },
    {
      id: 'assignedAmount',
      accessorKey: 'assignedAmount',
      header: t('common.assignedAmount'),
      meta: {
        topHeaderRowClass: 'font-bold justify-start',
        headerClassName: 'justify-start',
        cellClassName: 'justify-start',
        sizePercentage: 20,
      },
      cell: ({ cell }) => (
        <>
          <Input
            className={'bg-neutral-150 font-bold'}
            placeholder={'0 €'}
            defaultValue={
              isNaN(cell.row.getValue('assignedAmount')) || (cell.row.getValue('assignedAmount') as number) <= 0
                ? ''
                : stringUtils.currencyFormat(cell.row.getValue('assignedAmount') as number, currentCurrency) ?? ''
            }
            onBlur={(e) => {
              let value = parseFloat(e.target.value.replace(',', '.'));
              if (isNaN(value)) value = 0;
              if (value > (cell.row.getValue('remainingAmount') as number))
                // max value is remaining amount to pay
                value = cell.row.getValue('remainingAmount') as number;
              setAmount(cell.row.getValue('id'), value); // set amount
              if (value != 0) {
                e.target.value = stringUtils.currencyFormat(value, currentCurrency) ?? '';
              } else e.target.value = '';

              cell.row.toggleSelected(value > 0);
            }}
            onFocus={(e) => {
              if (e.target.value === '0') {
                e.target.value = '';
              }
              e.target.value = e.target.value.replace(/[^0-9.,]/g, ''); // remove currency formating
            }}
            disabled={cell.row.getValue('remainingAmount') === 0}
          />
        </>
      ),
    },
    {
      id: 'remainingAmount',
      accessorKey: 'remainingAmount',
      header: t('common.remainingAmountToPay'),
      meta: {
        topHeaderRowClass: 'font-bold justify-start',
        cellClassName: 'justify-start',
        sizePercentage: 29,
      },
      cell: ({ cell }) => (
        <>
          <Label
            className={
              !(cell.row.getValue('isSelected') as boolean)
                ? 'text-neutral-400'
                : Math.round(
                      (cell.row.getValue('remainingAmount') as number) -
                        (cell.row.getValue('assignedAmount') as number),
                    ) === 0
                  ? ''
                  : (cell.row.getValue('assignedAmount') as number) > 0 &&
                      (cell.row.getValue('remainingAmount') as number) -
                        (cell.row.getValue('assignedAmount') as number) >
                        0
                    ? 'text-notification'
                    : ''
            }
            title={
              Math.round(
                (cell.row.getValue('remainingAmount') as number) - (cell.row.getValue('assignedAmount') as number),
              ) === 0
                ? t('common.invoiceFullyPaid')
                : (cell.row.getValue('assignedAmount') as number) > 0
                  ? t('common.partiallyPaid')
                  : ''
            }
          >
            {stringUtils.currencyFormat(
              (cell.row.getValue('remainingAmount') as number) - (cell.row.getValue('assignedAmount') as number),
              currentCurrency,
            )}
          </Label>
        </>
      ),
    },
    {
      id: 'totalAmount',
      accessorKey: 'totalAmount',
      header: t('common.amount'),
      meta: {
        topHeaderRowClass: 'font-bold justify-end',
        cellClassName: 'justify-end',
        sizePercentage: 20,
      },
      cell: ({ cell }) => (
        <>
          <Label className={!(cell.row.getValue('isSelected') as boolean) ? 'text-neutral-400' : ''}>
            {stringUtils.currencyFormat(cell.row.getValue('totalAmount') as number, currentCurrency)}
          </Label>
        </>
      ),
    },
  ];

  const adjustedColumns = adjustColumnsSize<TablePaymentInvoice>({
    columns,
    savedColumns: savedColumns || [],
    tableWidth,
  });

  return adjustedColumns;
};
