import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Config from '../../../Config';
import './TruckZone.scss';
import '../../../styles/table.scss';
import { GetVehicle } from '../../../models/Vehicle';

const TruckZone = React.memo(() => {
  type Params = {
    id: string;
    orgid: string;
    mode: string;
  };

  const { id, orgid, mode } = useParams<Params>();

  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('trailerPlate');
  const [sortDirection, setSortDirection] = useState('asc');
  const history = useNavigate();
  const [filteredItems, setFilterereditems] = useState<any[]>([]);
  const [filterText, setFilterText] = useState('');
  const [tz, setTz] = useState<any>();

  const columns: any[] =
    (mode ?? null) !== 'big'
      ? [
          {
            name: (
              <div className='TruckZone-column-label'>
                <div className='captionG'>VÉHICULE</div>
              </div>
            ),
            selector: (row: GetVehicle) => row.registrationPlate,
            cell: (row: GetVehicle) => (
              <div className='TruckZone-column-label'>
                <div className='captionG'>{row.registrationPlate}</div>
              </div>
            ),
            sortable: true,
            right: false,
            id: 'registrationPlate',
            grow: 1,
          },
          {
            name: (
              <div className='TruckZone-column-label'>
                <div className='captionG'>DESTINATION</div>
              </div>
            ),
            selector: (row: any) => row.destinationZone.label,
            cell: (row: any) => (
              <div className='TruckZone-column-label captionG'>
                <div className='captionG'>{row.destinationZone.label}</div>
              </div>
            ),
            sortable: true,
            right: true,
            id: 'label',
            grow: 2,
          },
        ]
      : [
          {
            name: 'Parking',
            cell: (row: GetVehicle) => (
              <div className='TruckZone-column-label big'>
                <div className='captionG'>{row.registrationPlate}</div>
              </div>
            ),
            sortable: false,
            right: false,
            id: 'registrationPlate',
            grow: 3,
          },
        ];

  useEffect(() => {
    setFilterText('');
    fetchZone(1, perPage);
  }, []);

  const fetchZone = async (
    page: number,
    newPerPage: number,
    newFilterText: string = filterText,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
  ) => {
    const token = localStorage.getItem('token');

    const url = orgid + '/zone/' + id + '/display';

    setCurrentPage(page);
    setIsLoading(true);

    if (newFilterText != filterText) {
      setFilterText(newFilterText);
    }

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    await axios
      .get(Config.getUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        setFilterereditems(res.data.content.vehicles);
        setTz(res.data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
      });
  };

  return (
    <div className='truckZone'>
      <div className={''}>
        <div className='TitleList'>
          <h1>{tz?.zone}</h1>
        </div>

        {isLoading ? (
          <div>Chargement en cours</div>
        ) : (
          <DataTable
            className='table'
            noHeader={true}
            noTableHead={(mode ?? null) !== 'big' ? false : true}
            customStyles={TableStyles}
            columns={columns}
            data={filteredItems}
            noDataComponent={<div className='noResults'>{'Aucune info'}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            sortServer
          />
        )}
      </div>
    </div>
  );
});

TruckZone.displayName = 'TruckZone';
export default TruckZone;
