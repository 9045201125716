import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { ApiErrorMessages, decodeApiError } from '../utils/apiErrorHandler';
import { SelectedFilterValueRadio, SelectedFilterValuesByColumn } from '../components/ui/organisms/table/utils';
import { DateRange } from 'react-day-picker';

export type Dependency =
  | string
  | string[]
  | number
  | boolean
  | SelectedFilterValuesByColumn
  | SelectedFilterValueRadio
  | DateRange
  | Date
  | undefined
  | null;

type UseFetchDataOnLoadProps<T> = {
  fetchData: () => Promise<T>;
  errorMessages: ApiErrorMessages;
  controller?: AbortController;
  dependencies?: Dependency[];
};

export type UseFetchDataOnLoadResult<T> = {
  data: T | null;
  isLoading: boolean;
  errorMessage: string | null;
};

const useFetchDataOnLoad = <T>({
  fetchData,
  errorMessages,
  controller,
  dependencies = [],
}: UseFetchDataOnLoadProps<T>): UseFetchDataOnLoadResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setData(null);
  }, dependencies);

  useEffect(() => {
    const fetchDataHandler = async () => {
      setIsLoading(true);

      try {
        const response = await fetchData();
        setData(response);
      } catch (error) {
        const isCanceledError =
          (error as AxiosError).name === 'CanceledError' || (error as AxiosError).code === 'ERR_CANCELED';
        if (!isCanceledError) {
          const message = decodeApiError(error as AxiosError, errorMessages);
          setErrorMessage(message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataHandler();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, dependencies);

  return { data, isLoading, errorMessage };
};

export default useFetchDataOnLoad;
