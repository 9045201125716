import { ToastTypes } from '../models/ToastTypes';
import { toast } from 'sonner';

// Added to avoid the serializable error

function useToast() {
  const addToast = (message: string, type: ToastTypes) => {
    if (type === ToastTypes.error) {
      toast.error(message);
    }
    else if (type === ToastTypes.success) {
      toast.success(message);
    }
    else {
      toast.info(message);
    }
  };

  return { addToast };
}

export default useToast;
