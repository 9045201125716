import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import DetailVehicle from '../pages/Vehicles/DetailVehicle';
import { GetVehicle, GetVehicleParams, GetVehiclePayload } from '../../models/Vehicle';
import { useNavigate } from 'react-router-dom';
import useAuthGuard from '../../hooks/useAuthGuard';
import useToast from '../../hooks/useToast';
import { handleApiError } from '../../utils/apiErrorHandler';
import { fetchVehicleDetails, fetchVehicles } from '../../services/vehicle';
import { t } from 'i18next';

export interface SelectVehicleOption extends SelectDataOption {
  data: GetVehicle;
}

const generateVehicleOptions = (vehicles: GetVehicle[]) => {
  const options =
    vehicles.map((vehicle) => ({
      value: vehicle.id,
      label: vehicle.registrationPlate ?? '',
      data: vehicle,
    })) || [];

  return options;
};

export const SelectVehicle = ({
  setValue,
  register,
  registerName = 'vehicle',
  error,
  selectedOptionChanged,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectDataOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectVehicleOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const fetchVehiclesByQuery = async (filterText: string) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setFilterText(filterText);
      if (filterText.length <= 0 && (searchParent ?? null) === null) {
        setOptions([]);
        return;
      }
      setIsLoading(true);

      const params: GetVehicleParams = {
        page: 1,
        limit: 200,
        search: filterText,
      };

      const payload: GetVehiclePayload = {
        registrationPlate: filterText,
        isActive: true,
      };

      const { items } = await fetchVehicles({
        accessToken: token,
        orgid,
        params,
        payload,
      });
      const options = generateVehicleOptions(items);
      setOptions(options);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  const addPopupClosedHandler = async (createdId?: string) => {
    setShowAddPopup(false);

    try {
      if (!token || !orgid || !createdId) {
        return;
      }

      setIsLoading(true);

      const fetchedVehicleDetails = await fetchVehicleDetails({
        accessToken: token,
        orgid,
        vehicleId: createdId,
      });

      const { id, registrationPlate } = fetchedVehicleDetails;

      setCreatedOption({
        value: id,
        label: registrationPlate || '',
        data: fetchedVehicleDetails,
      });
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
      generateVehicleOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAddPopup && <DetailVehicle mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} />}
      <SelectData
        title={props.titleOverride ?? t('common.vehicle')}
        placeholder={t('common.vehicleChoose')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchVehiclesByQuery}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        isPopup={props.isPopup}
        onClosePopup={props.onClosePopup}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};
