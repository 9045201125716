import React from 'react';
import { Checkbox } from '../../atoms/Checkbox';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../atoms/Tooltip';
import { Row, Table } from '@tanstack/react-table';

type RenderCheckboxProps<T> = {
  item: T;
  isDisabled?: boolean;
};

export const renderRowCheckbox = <T,>({ item: row, isDisabled = false }: RenderCheckboxProps<Row<T>>) => (
  <Checkbox
    checked={row.getIsSelected()}
    onCheckedChange={(value) => row.toggleSelected(!!value)}
    aria-label='Select row'
    className='translate-y-[2px]'
    disabled={isDisabled}
  />
);

export const renderHeaderCheckbox = <T,>({ item: table, isDisabled = false }: RenderCheckboxProps<Table<T>>) => (
  <Checkbox
    checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
    onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    aria-label='Select all'
    className='translate-y-[2px]'
    disabled={isDisabled}
  />
);

export const renderTooltipCheckbox = <T,>({
  renderCheckbox,
  item,
  isDisabled,
  content,
}: {
  renderCheckbox: (props: RenderCheckboxProps<T>) => JSX.Element;
  item: T;
  isDisabled: boolean;
  content?: string;
}) => {
  return isDisabled ? (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{renderCheckbox({ item, isDisabled })}</TooltipTrigger>
        <TooltipContent side='right' align='center' className='text-destructive'>
          <span>{content}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    renderCheckbox({ item, isDisabled })
  );
};
