import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/molecules/Form';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../ui/molecules/Card';
import { Input } from '../ui/atoms/input/Input';
import { Button } from '../ui/atoms/button/Button';
import Alert from '../ui/molecules/alert/Alert';
import { t } from 'i18next';
import validator from 'validator';
import { sendPasswordResetRequest } from '../../services/auth';
import useFetchData from '../../hooks/useFetchData';

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .test('is-email', t('login.invalidEmail'), (value) => validator.isEmail(value || ''))
    .required(t('login.requiredEmail')),
});

type Credentials = yup.InferType<typeof forgotPasswordSchema>;

type ForgotPasswordFormProps = {
  formEmail?: string | null;
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ formEmail }) => {
  const { t } = useTranslation();

  const { isLoading, isSuccess, errorMessage, fetchDataHandler } = useFetchData<void, string>({
    fetchData: (email) => (email ? sendPasswordResetRequest(email) : Promise.reject(new Error('Email is undefined'))),
    errorMessages: {
      ERR4010002: t('login.resetPassword.results.notFound'),
      ERR5000000: t('login.serverError'),
      default: t('login.errorMessage'),
    },
  });

  const form = useForm<Credentials>({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: formEmail || '',
    },
  });

  const isSubmitable = form.formState.isValid;

  const handleResetPasswordRequest = async (credentials: Credentials) => {
    fetchDataHandler(credentials.email);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleResetPasswordRequest)}>
        <Card className='w-96'>
          <CardHeader className='items-center'>
            <CardTitle>{t('login.forgetPasswordTitle')}</CardTitle>
          </CardHeader>

          <CardContent>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('login.email')}</FormLabel>
                  <FormControl>
                    <Input {...field} type='email' placeholder='exemple@email.com' />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className='flex-col items-start'>
            <Button disabled={!isSubmitable}>{t('login.resetPassword.reset')}</Button>

            {isLoading ? <div>Loading</div> : null}
            {errorMessage && !isSuccess && !isLoading ? (
              <Alert className='mt-4' variant='destructive' description={errorMessage} />
            ) : null}
            {isSuccess ? (
              <Alert className='mt-4' variant='warning' description={t('login.resetPassword.results.pending')} />
            ) : null}
          </CardFooter>
        </Card>
      </form>
    </Form>
  );
};

export default ForgotPasswordForm;
