import { toast } from 'sonner';
import { Option } from '../../../../types';
import { hasId, isArrayWithId } from '../../../../utils/typeguards';
import { ColumnDef, Table } from '@tanstack/react-table';
import { NA } from '../../../../constants/constants';

export type SelectedFilterValuesByColumn = {
  [key: string]: Set<string>;
};

export type ColumnsFilterOptions = Record<string, Option[]>;

export type SelectedFilterValueRadio = {
  [key: string]: string;
};

export const generateSelectedFilterValuesByColumn = (
  selectedFilterValuesByColumn: SelectedFilterValuesByColumn,
  columnsFilterOptions: ColumnsFilterOptions,
) => {
  const columnsFilterOptionsKeys = Object.keys(columnsFilterOptions);
  const selectedFilterValuesByColumnKeys = Object.keys(selectedFilterValuesByColumn);

  const missingKeys = columnsFilterOptionsKeys.filter((key) => !selectedFilterValuesByColumnKeys.includes(key));
  const extraKeys = selectedFilterValuesByColumnKeys.filter((key) => !columnsFilterOptionsKeys.includes(key));

  extraKeys.forEach((key) => {
    delete selectedFilterValuesByColumn[key];
  });

  const emptySelectedFilterValuesByColumn = missingKeys.reduce<SelectedFilterValuesByColumn>((acc, currKey) => {
    return { ...acc, ...{ [currKey]: new Set<string>() } };
  }, {});

  return { ...selectedFilterValuesByColumn, ...emptySelectedFilterValuesByColumn };
};

type UpdateDataAndRefArgs<TRow, TRowTransformed> = {
  row: TRowTransformed;
  updatedLocalRow: TRow;
  localData: TRow[];
  updateLocalData: (data: TRow[]) => void;
  initialRowsRef: React.MutableRefObject<TRowTransformed[]>;
  updateInitialRowsRef: (updatedInitialRowsRef: TRowTransformed[]) => void;
  id: string | number;
  successMessage: string;
  sortFunction?: (a: TRow, b: TRow) => number;
  filterFunction?: (row: TRow) => boolean;
};

export const updateDataAndRef = <TRow, TRowTransformed>({
  row,
  updatedLocalRow,
  initialRowsRef,
  localData,
  updateLocalData,
  updateInitialRowsRef,
  id,
  successMessage,
  sortFunction,
  filterFunction,
}: UpdateDataAndRefArgs<TRow, TRowTransformed>) => {
  const initialRows = initialRowsRef.current;
  if (!hasId(row) || !isArrayWithId<TRowTransformed>(initialRows) || !isArrayWithId<TRow>(localData)) {
    return;
  }

  const updatedInitialRowsRef = initialRows.filter((row) => row.id !== id.toString());
  updateInitialRowsRef(updatedInitialRowsRef);

  const initialLocalData = localData.filter((row) => row.id.toString() !== id.toString());
  const updatedData = [...initialLocalData, updatedLocalRow].filter(filterFunction ?? (() => true)).sort(sortFunction);
  updateLocalData(updatedData);

  toast.success(successMessage);
};

const convertToNumber = (value?: string | null): number => {
  if (!value || value === NA) {
    return 0;
  }

  const cleanedString = value.trim();
  const stringWithoutCommas = cleanedString.replace(/,/g, '.');
  const numericString = stringWithoutCommas.replace(/[^\d.%-]/g, '');
  const numericValue = numericString.endsWith('%') ? parseFloat(numericString) / 100 : parseFloat(numericString);

  if (isNaN(numericValue)) {
    throw new Error('Impossible de convertir la chaîne en nombre.');
  }

  return numericValue;
};

export const calculateTotalFooter = <TData>(table: Table<TData>, column: string): string => {
  const rows = table.getFilteredRowModel().rows;
  const total = rows.reduce<number>((total, row) => total + convertToNumber(row.getValue(column)), 0).toLocaleString();

  return total;
};

export const calculatePercentageFooter = <TData>(table: Table<TData>, column: string): string => {
  const rows = table.getFilteredRowModel().rows;
  const totalPercentage = rows.reduce<number>((total, row) => total + convertToNumber(row.getValue(column)), 0) * 100;

  return `${Math.round(totalPercentage).toFixed(2)} %`;
};

export const calculateColumnSize = (tableWidth: number, sizePercentage?: number) =>
  (tableWidth * (sizePercentage ?? 0)) / 100;

const calculateColumnSizeAccordingToSavedColumns = <T>({
  columns,
  savedColumns,
}: {
  columns: ColumnDef<T>[];
  savedColumns: string[];
}) => {
  if (savedColumns.length === 0) {
    const defaultPercentages = columns.map((column) => column.meta?.sizePercentage ?? 0);
    return defaultPercentages;
  }

  const columnsToDisplay = columns.filter((column) => column.id && savedColumns.includes(column.id));

  const totalSize = columnsToDisplay.reduce((acc, column) => acc + (column.meta?.sizePercentage || 0), 0);
  if (totalSize > 100) {
    const defaultPercentages = columns.map((column) => column.meta?.sizePercentage ?? 0);
    return defaultPercentages;
  }

  const newPercentages = columns.map((column) => {
    const isColumnSaved = column.id && savedColumns.includes(column.id);
    const size = column.meta?.sizePercentage ?? 0;
    const sizePercentage = isColumnSaved ? size : 0;

    return (sizePercentage / totalSize) * 100;
  });

  return newPercentages;
};

export const adjustColumnsSize = <T>({
  columns,
  savedColumns,
  tableWidth,
}: {
  columns: ColumnDef<T>[];
  savedColumns: string[];
  tableWidth: number;
}) => {
  const columnsSize = calculateColumnSizeAccordingToSavedColumns({
    columns,
    savedColumns: savedColumns || [],
  });

  const adjustedColumns = columns.map((column, index) => {
    const size = calculateColumnSize(tableWidth, columnsSize[index]);

    return {
      ...column,
      size,
    };
  });

  return adjustedColumns;
};
