import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralPayment from './GeneralPayment';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import { t } from 'i18next';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../../ui/molecules/Dialog';
import { Button } from '../../ui/atoms/button/Button';
import { Form } from '../../ui/molecules/Form';

interface DetailBuildingSiteProps {
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
  dataParent?: string;
}

const DetailPayment = React.memo((props: DetailBuildingSiteProps) => {
  // #region generic

  const [isLoading, setIsLoading] = useState(false);

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    tab?: string;
  };

  const { id, mode } = useParams<Params>();

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific

  // TODO : payment detail loading not implemented yet

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralPayment
              pageMode={getMode()}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
              dataParent={props.dataParent}
            />
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/invoices');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/invoices');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={'alternative'}>{t('common.addNewPayment')}</Button>
          </DialogTrigger>
          <DialogContent className='max-w-3xl'>
            <DialogHeader>
              <DialogTitle className='text-3xl'></DialogTitle>
            </DialogHeader>
            {content}
          </DialogContent>
        </Dialog>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='invoices'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={getMode() === 'add' ? t('common.newPayment') : t('common.payment')}
              back={true}
              Back={function (): void {
                history('/invoices');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailPayment.displayName = 'DetailPayment';
export default DetailPayment;
