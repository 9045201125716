import DefaultModel from './DefaultModel';

export enum BillingType {
  PUNCTUAL = 'punctual',
  MONTHLY = 'monthly',
}

export enum ContactEntity {
  BUILDING_SITE = 'building_site',
  CUSTOMER = 'customer',
  SITE = 'site',
  VEHICLE = 'vehicle',
  CARRIER = 'carrier',
}

export interface GetContactParameters {
  entityId: string;
  contactEntity: ContactEntity;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
}

interface ContactEntityGet {
  id: string;
  label: string;
}

export interface GetContact {
  id: string;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  lastModificationAt: Date;
  lastModificationBy: string;
  lastname?: string;
  firstname: string;
  telephone?: string;
  email?: string;
  companyRole?: string;
  buildingSites: ContactEntityGet[];
  carriers: ContactEntityGet[];
  customers: ContactEntityGet[];
  sites: ContactEntityGet[];
  vehicles: ContactEntityGet[];
}

export interface ContactSave {
  firstname: string;
  entityId: string;
  contactEntity: ContactEntity;
  lastname?: string;
  telephone?: string;
  email?: string;
  companyRole?: string;
}

export default class Contact extends DefaultModel {
  lastname?: string;
  firstname?: string;
  telephone?: string;
  email?: string;
  companyRole?: string;
  affiliateId?: string;
}
