import axios from 'axios';
import { GetInvoice, GetInvoiceParameters, InvoiceSave, ValidateProformaParameters } from '../models/Invoice';
import Config, { createInstance } from '../Config';

interface InvoiceParams {
  baseUrl: string;
  token: string;
  payload: InvoiceSave;
}

export const saveInvoice = async ({ baseUrl, token, payload }: InvoiceParams) => {
  const url = Config.getApiExtranetUrl(baseUrl);
  await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

interface ConvertToProformaParams {
  baseUrl: string;
  token: string;
  payload: ValidateProformaParameters;
}

export const convertProformasToInvoices = async ({ baseUrl, token, payload }: ConvertToProformaParams) => {
  const url = Config.getApiExtranetUrl(baseUrl);
  const response = await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.content.invoices;
};

const instance = createInstance({ isExtranet: true });
export const fetchInvoices = async ({
  accessToken,
  orgid,
  payload,
  controller,
}: {
  accessToken: string;
  orgid: string;
  payload: GetInvoiceParameters;
  controller: AbortController;
}): Promise<GetInvoice[]> => {
  const response = await instance.post(
    `${orgid}/invoice/get`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: controller.signal,
    },
  );
  return response.data.content.items;
};
