import { ColumnDef, VisibilityState } from '@tanstack/react-table';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTableName } from './useTableName';
import { hasId } from '../utils/typeguards';
import { useLocation } from 'react-router-dom';

const retrieveSavedColumns = <TRow>(columns: ColumnDef<TRow>[], savedColumns?: string[]): VisibilityState => {
  if (!savedColumns || !savedColumns.length) {
    return {};
  }

  const keys = columns.filter(hasId).map(({ id }) => id);
  return keys.reduce<VisibilityState>((acc, key) => {
    acc[key] = savedColumns.includes(key);
    return acc;
  }, {});
};

export const useColumnsVisibility = <TRow>(columns: ColumnDef<TRow>[]) => {
  const tableName = useTableName();
  const [cookies] = useCookies(['userPreferences', 'userDetail']);
  const currentUserMail = cookies.userDetail?.email;

  const { hash } = useLocation();
  const columnKey = hash ? `${hash.slice(1)}SavedColumns` : `${tableName}SavedColumns`;

  const columnsCookie = cookies.userPreferences?.[`${currentUserMail}`]?.[columnKey];
  const savedColumns = retrieveSavedColumns(columns, columnsCookie);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(savedColumns);

  return { columnVisibility, setColumnVisibility };
};
