import { jwtDecode } from 'jwt-decode';
import Workspace from '../models/Workspace';
import { WorkspaceTypes } from '../models/WorkspaceTypes';
import Organization from '../models/Organization';
import PermissionResolver from '@synaxe/permission-resolver/dist';
import eventBus from '../utils/EventBus';
import axios from 'axios';
import Config from '../Config';

export default class Roles {
  static getToken = () => {
    const token = localStorage.getItem('token');
    if (token != null) {
      const decoded = jwtDecode<any>(token?.toString());
      return decoded;
    } else {
      return null;
    }
  };

  static getCurrentWorkspaceUuid = () => {
    const workspace = this.getCurrentWorkspace();
    if (workspace != null) {
      return workspace.organization?.uuid;
    }
    return null;
  };

  static getCurrentWorkspace = (): Workspace | null => {
    const currentWorkspace = localStorage.getItem('currentWorkspace');
    if (currentWorkspace != null) {
      return JSON.parse(currentWorkspace);
    }
    return currentWorkspace;
  };

  static setWorkspace(workspace: Workspace) {
    localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
  }

  static getWorkspaces = (): Workspace[] => {
    const workspaces = localStorage.getItem('allUsersWorkspaces');
    if (workspaces != null) {
      return JSON.parse(workspaces);
    }
    return [];
  };

  static getEmail() {
    const email = localStorage.getItem('email');
    if (email != null) {
      return email;
    }
  }

  static setLocalStorage(workspace: Workspace) {
    localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
    localStorage.setItem('orgid', workspace.organization?.uuid ?? '');
    eventBus.dispatch('updateWorkspace', { workspaceId: workspace.organization?.uuid as string });

    const token = localStorage.getItem('token') as string;

    if ((workspace.organization?.uuid ?? null) !== null) {
      axios
        .get(Config.getApiExtranetUrl() + (workspace.organization?.uuid as string) + '/site-data-custom-type/get', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Garder les dataCustom en LocalStorage en format JSON
          if (res.data.content.length > 0) {
            localStorage.setItem('paramTripDataCustom', JSON.stringify(res.data.content[0].tripDataCustom));
            localStorage.setItem('paramCustomerDataCustom', JSON.stringify(res.data.content[0].customerDataCustom));
            localStorage.setItem('paramVehicleDataCustom', JSON.stringify(res.data.content[0].vehicleDataCustom));
            localStorage.setItem('paramProductDataCustom', JSON.stringify(res.data.content[0].productDataCustom));
            localStorage.setItem('paramCarrierDataCustom', JSON.stringify(res.data.content[0].carrierDataCustom));
            localStorage.setItem(
              'paramBuildingSiteDataCustom',
              JSON.stringify(res.data.content[0].buildingSiteDataCustom),
            );
          } else {
            localStorage.setItem('paramTripDataCustom', 'null');
            localStorage.setItem('paramCustomerDataCustom', 'null');
            localStorage.setItem('paramVehicleDataCustom', 'null');
            localStorage.setItem('paramProductDataCustom', 'null');
            localStorage.setItem('paramCarrierDataCustom', 'null');
            localStorage.setItem('paramBuildingSiteDataCustom', 'null');
          }
        })
        .catch(() => {
          localStorage.setItem('paramTripDataCustom', 'null');
          localStorage.setItem('paramCustomerDataCustom', 'null');
          localStorage.setItem('paramVehicleDataCustom', 'null');
          localStorage.setItem('paramProductDataCustom', 'null');
          localStorage.setItem('paramCarrierDataCustom', 'null');
          localStorage.setItem('paramBuildingSiteDataCustom', 'null');
        });

      axios
        .get(Config.getApiExtranetUrl() + (workspace.organization?.uuid as string) + '/global-parameter/get', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Garder les dataCustom en LocalStorage en format JSON
          localStorage.setItem('globalParameters', JSON.stringify(res.data.content));
        })
        .catch(() => {
          localStorage.setItem('globalParameters', 'null');
        });

      const customerGetUrl =
        (workspace.organization?.uuid as string) +
        '/customer-type/get' +
        '?page=1&limit=1000&sortBy=label&orderBy=desc';

      axios
        .post(
          Config.getApiExtranetUrl(customerGetUrl),
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          // Garder les customerTypes en LocalStorage en format JSON
          localStorage.setItem('customerTypes', JSON.stringify(res.data.content.items));
        })
        .catch(() => {
          localStorage.setItem('customerTypes', '[]');
        });

      const productTypeGetUrl =
        (workspace.organization?.uuid as string) + '/product-type/get' + '?onlyActive=true&page=1&limit=200';

      axios
        .get(Config.getApiExtranetUrl(productTypeGetUrl), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Garder les customerTypes en LocalStorage en format JSON
          localStorage.setItem('productTypes', JSON.stringify(res.data.content.items));
        })
        .catch(() => {
          localStorage.setItem('productTypes', '[]');
        });

      axios
        .get(Config.getApiExtranetUrl() + (workspace.organization?.uuid as string) + '/waste-request/host', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Garder le wasteRequestHost en LocalStorage en format JSON
          localStorage.setItem('wasteRequestHost', JSON.stringify(res.data.content));
        })
        .catch(() => {
          localStorage.setItem('wasteRequestHost', 'null');
        });
    }
  }

  static orderWorkspaces = (workspaces: any[]) => {
    const data: Workspace[] = [];
    workspaces.forEach((value: Workspace) => {
      if ((value.organization?.uuid ?? null) === null) {
        return;
      }

      data.push(value);
    });

    return data.sort((a, b) => (a.organization?.name ?? '').localeCompare(b.organization?.name ?? ''));
  };

  static getWorkspaceType(organization: Organization | undefined) {
    if (organization?.type == 'billing') {
      return 'delivery';
    } else {
      return organization?.type;
    }
  }

  static saveWorkspaces(data: any[]): Workspace[] {
    const sortedWorkspaces = this.orderWorkspaces(data);
    this.setWorkspace(sortedWorkspaces[0]);
    localStorage.setItem('allUsersWorkspaces', JSON.stringify(sortedWorkspaces));

    return sortedWorkspaces;
  }

  static containsScope(scopes: string[], currentScope: string) {
    const resolver = new PermissionResolver({
      admin: {
        site: { view: [], add: [], remove: [], manage: ['view', 'add', 'remove'] },
        affiliate: { view: [], add: [], remove: [], manage: ['view', 'add', 'remove'] },
        role: { view: [], add: [], remove: [], addscope: [], manage: ['view', 'add', 'remove', 'addscope'] },
        scope: { view: [], add: [], remove: [], manage: ['view', 'add', 'remove'] },
      },
      mobile: {
        zone: { view: [] },
        product: { view: [] },
        trip: { view: [], detail: [], processing: [] },
      },
      extranet: {
        additionaldoc: {
          add: [],
          edit: [],
          remove: [],
          view: [],
          detail: [],
          manage: ['view', 'add', 'remove', 'detail', 'edit'],
        },
        alert: { acknowledge: [], view: [], manage: ['view', 'acknowledge'] },
        authorizedvehicle: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        bankoutstanding: { view: [], add: [], edit: [], detail: [], manage: ['view', 'add', 'detail', 'edit'] },
        buildingsite: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        carrier: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          addvehicle: [],
          removevehicle: [],
          manage: ['view', 'add', 'edit', 'detail', 'addvehicle', 'removevehicle'],
        },
        contact: {
          add: [],
          remove: [],
          view: [],
          detail: [],
          manage: ['view', 'add', 'remove', 'detail'],
        },
        customer: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        invoice: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        procurementnote: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        product: { view: [], add: [], edit: [], detail: [], manage: ['view', 'add', 'detail', 'edit'] },
        quotation: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        ressourcetype: { view: [], manage: ['view'] },
        terminal: { view: [], detail: [], sendcommand: [], manage: ['view', 'sendcommand', 'detail'] },
        trip: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail'],
        },
        vehicle: {
          view: [],
          add: [],
          edit: [],
          detail: [],
          addcarrier: [],
          removecarrier: [],
          manage: ['view', 'add', 'edit', 'detail', 'addcarrier', 'removecarrier'],
        },
        zone: {
          view: [],
          add: [],
          addproduct: [],
          removeproduct: [],
          edit: [],
          detail: [],
          manage: ['view', 'add', 'edit', 'detail', 'addproduct', 'removeproduct'],
        },
      },
      core: {
        user: { me: [] },
      },
    });
    if (resolver.verifyScopeAuthorized(scopes, currentScope) === true) {
      return true;
    } else {
      return false;
    }
  }

  static getLibelle(scopes: string[] | undefined): string {
    if (scopes != null) {
      if (scopes?.filter((s) => s.includes('manage')).length > 0) {
        return 'Admin';
      } else {
        return 'Utilisateur';
      }
    } else {
      return 'Utilisateur';
    }
  }

  static hasRight(permission: string) {
    const wkspace = this.getCurrentWorkspace();

    if (wkspace != null) {
      return this.containsScope(wkspace.scopes, permission);
    }
    return false;
  }

  static isUserAffiliate = () => {
    const workspace = this.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.affiliate;
  };

  static isUserSite = () => {
    const workspace = this.getCurrentWorkspace();
    return workspace?.organization?.type == WorkspaceTypes.site;
  };

  static getOrganizationDisplayType(type: string | undefined) {
    switch (type) {
      case WorkspaceTypes.affiliate:
        return 'Affilié';
      case WorkspaceTypes.site:
        return 'Site';
    }
  }

  static saveUser(uuid: string) {
    localStorage.setItem('userUuid', uuid);
    Roles.setHotjarUserId();
  }

  static getUserId() {
    return localStorage.getItem('userUuid');
  }

  static getCurrentRole = () => {
    const workspace = Roles.getCurrentWorkspace();
    return workspace?.organization?.type;
  };

  static setHotjarUserId() {
    const userId = Roles.getUserId() || null; // Replace your_user_id with your own if available.
    const currentWindow: any = window;
    const hotjarUserPayload = {
      role: Roles.getCurrentRole(),
      mail: Roles.getEmail(),
      env: process.env.REACT_APP_DEV_ENVIRONMENT,
      organizaionUuid: Roles.getCurrentWorkspaceUuid(),
      userUuid: Roles.getUserId(),

      // Add your own custom attributes here. Some EXAMPLES:
      // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
      // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
      // 'Total purchases': 15, // Send numbers without quotes.
      // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
      // 'Last refund date': null, // Send null when no value exists for a user.
    };
    currentWindow.hj('identify', userId, hotjarUserPayload);
  }
}
