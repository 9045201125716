import React, { createContext, Dispatch, useReducer, useContext, ReactNode, useState } from 'react';
import { tableReducer, TableState, TableAction } from './tableReducer';
import { ColumnsFilterOptions } from '../components/ui/organisms/table/utils';

type TableContextType<TData> = {
  state: TableState<TData>;
  dispatch: Dispatch<TableAction<TData>>;
  pageTitleQueryKey?: string;
  url?: string;
  dateFrom?: string;
  dateTo?: string;
  urlHashTo?: string;
  columnsFilterOptions: ColumnsFilterOptions;
  tableHeight: number;
  setTableHeight: (height: number) => void;
  tableWidth: number;
  setTableWidth: (width: number) => void;
};

type TableProviderProps = {
  children: ReactNode;
  pageTitleQueryKey?: string;
  url?: string;
  dateFrom?: string;
  dateTo?: string;
  urlHashTo?: string;
  columnsFilterOptions?: ColumnsFilterOptions;
};

const initialState: TableState<unknown> = {
  tableInstance: null,
};

const TableContext = createContext<TableContextType<unknown> | undefined>(undefined);

const useTable = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error('useTable should be use within TableProvider');
  }
  return context;
};

const TableProvider: React.FC<TableProviderProps> = ({
  children,
  pageTitleQueryKey,
  url,
  urlHashTo,
  dateFrom,
  dateTo,
  columnsFilterOptions = {},
}) => {
  const [state, dispatch] = useReducer(tableReducer, initialState);
  const [tableHeight, setTableHeight] = useState<number>(0);
  const [tableWidth, setTableWidth] = useState<number>(0);

  const value = {
    state,
    dispatch,
    pageTitleQueryKey,
    url,
    urlHashTo,
    dateFrom,
    dateTo,
    columnsFilterOptions,
    tableHeight,
    setTableHeight,
    tableWidth,
    setTableWidth,
  };

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>;
};

export { TableProvider, useTable };
