import { RefObject, useEffect } from 'react';
import { useTable } from '../context/TableProvider';

const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer?.parentNode?.removeChild(outer);

  return scrollbarWidth;
};

export const useResponsiveWidth = <T>(htmlTableRef: RefObject<HTMLTableElement>, data: T) => {
  const { setTableWidth } = useTable();

  useEffect(() => {
    const updateWidth = () => {
      if (htmlTableRef.current) {
        const scrollbarWidth = getScrollbarWidth();
        const tableWidth = htmlTableRef.current.clientWidth - scrollbarWidth;
        setTableWidth(tableWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [htmlTableRef, data]);
};
