import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectCustomerOption } from '../../forms/SelectCustomer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import stringUtils from '../../../utils/stringUtils';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchCustomerDetails } from '../../../services/customer';
import { ToastTypes } from '../../../models/ToastTypes';
import Payment, { paymentModeOptions, PaymentSave } from '../../../models/Payment';
import { createPayment } from '../../../services/payments';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../ui/molecules/Form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/molecules/SelectInput';
import { DialogClose, DialogFooter, DialogTitle } from '../../ui/molecules/Dialog';
import TextArea from '../../ui/molecules/textArea/TextArea';
import { Button } from '../../ui/atoms/button/Button';
import FormCombobox from '../../ui/molecules/FormCombobox';
import useSearchCustomer from '../../../hooks/useSearchCustomer';
import { Input } from '../../ui/atoms/input/Input';
import InvoicesToPayTable from './InvoicesToPayTable';
import { TableProvider } from '../../../context/TableProvider';
import { TablePaymentInvoice } from './types';
import { Label } from '../../ui/atoms/label/Label';
import { currentCurrency } from '../../../utils/priceUtils';

type GeneralPaymentProps = {
  onCancel: () => void;
  onConfirm: (data: any) => void;
  pageMode: string;
  dataParent?: string;
  paymentData?: Payment;
};

const MAX_ITEMS = 100;

const schema = yup.object().shape({
  paymentMode: yup.string().required(),
  amount: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value)) // Transform non-numeric input to undefined
    .required(t('common.fieldRequired'))
    .min(0, t('common.shouldBePositive')),
  transactionNumber: yup.string(),
  customerId: yup.string().required(),
  invoices: yup.array().of(
    yup.object().shape({
      invoiceId: yup.string().required(),
      amount: yup.number().required(),
      isFullyPaid: yup.boolean().required(),
    }),
  ),
}); // on utilise un validateur de 'validator.js' pour correspondre au back

type FormInputs = yup.InferType<typeof schema>;

const generateFormDefaultValues = ({
  dataParent,
  paymentData,
}: {
  dataParent?: string;
  paymentData?: Payment | null;
}) => {
  const formDefaultValues = {
    paymentMode: paymentData?.mode ?? '',
    amount: paymentData?.amount ?? 0,
    transactionNumber: paymentData?.transactionNumber ?? '',
    customerId: dataParent ?? '',
    invoices: [],
  };

  return formDefaultValues;
};

const GeneralPayment: React.FC<GeneralPaymentProps> = ({ onConfirm, onCancel, pageMode, dataParent, paymentData }) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const isEditMode = pageMode === 'update';

  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(null);
  const [currentCustomer, setCurrentCustomer] = useState<string>(dataParent || '');
  const [amount, setAmount] = useState<number>(0);
  const [paymentInvoices, setPaymentInvoices] = useState<TablePaymentInvoice[]>([]);
  const [assignedAmount, setAssignedAmount] = useState<number>(0);

  const formDefaultValues = generateFormDefaultValues({ dataParent, paymentData });

  const form: UseFormReturn<FormInputs> = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const {
    filterText: searchCustomer,
    fetchByQuery: setSearchCustomer,
    searchOptions: filteredCustomerOptions,
  } = useSearchCustomer();

  useEffect(() => {
    const fetchCurrentCustomerDetails = async (id?: string) => {
      if (!token || !orgid || !id) {
        return;
      }

      try {
        const fetchedCustomerDetails = await fetchCustomerDetails({ accessToken: token, orgid, customerId: id });

        const { id: fetchedCustomerId, label } = fetchedCustomerDetails;
        form.setValue('customerId', fetchedCustomerId);

        setCustomerOption({
          value: fetchedCustomerId,
          label: label || '',
          data: fetchedCustomerDetails,
        });
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      }
    };

    if (currentCustomer && currentCustomer !== '') fetchCurrentCustomerDetails(dataParent);
  }, [currentCustomer]);

  const onSubmit = async () => {
    try {
      if (!token || !orgid) {
        return;
      }

      const payload: PaymentSave = stringUtils.formatFieldsForPost({
        mode: form.getValues('paymentMode'),
        amount: form.getValues('amount'),
        invoices: form.getValues('invoices'),
      });
      // TODO temp : remove when backend is ready
      // payload.transactionNumber = '-';
      // payload.payerName = '-';
      // payload.bank = '-';

      if (isEditMode) {
        // not implemented yet
      } else {
        const { uuid } = await createPayment({ orgid, accessToken: token, payload });
        onConfirm(uuid);
      }
      addToast(t('common.payment' + (isEditMode ? 'Updated' : 'Created')), ToastTypes.success);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    }
  };

  function handleCustomerChange(value: string) {
    setCurrentCustomer(value);
    // TODO : voir si possible de gérer la déselection du client ?
  }

  function handleSetPaymentInvoices(invoices: TablePaymentInvoice[]) {
    setPaymentInvoices(invoices);
    const totalAssignedAmount = invoices.reduce((acc, curr) => acc + curr.assignedAmount, 0);
    setAssignedAmount(totalAssignedAmount);
    form.setValue(
      'invoices',
      invoices
        .filter((x) => x.isSelected)
        .map(({ id, assignedAmount, remainingAmount }) => ({
          invoiceId: id,
          amount: assignedAmount,
          isFullyPaid: Math.round(remainingAmount - assignedAmount) === 0,
        })),
    );
  }

  function unassignedAmount() {
    return Math.round((amount - assignedAmount) * 100) / 100;
  }

  return (
    <Form {...form}>
      <form id='form' onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
        <DialogTitle className='text-2xl'>{t('common.paymentInfo')}</DialogTitle>
        <div className='mb-6 grid grid-cols-2 gap-4'>
          <FormField
            control={form.control}
            name='paymentMode'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='flex items-center gap-2 text-base font-normal' required>
                  {t('common.paymentMode')}
                </FormLabel>
                <FormControl>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl className='bg-neutral-150'>
                      <SelectTrigger>
                        <SelectValue placeholder={t('common.paymentModeChoose')} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className='max-h-[200px]'>
                      {paymentModeOptions.map(({ value, label }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='amount'
            render={({ field }) => (
              <FormItem>
                <FormLabel className='text-base font-normal' required>
                  {t('common.amountInEuros')}
                </FormLabel>
                <FormControl
                  onBlur={() => {
                    {
                      setAmount(form.getValues('amount'));
                    }
                  }}
                  className='bg-neutral-150'
                >
                  <Input
                    {...field}
                    placeholder={t('common.enterAmount')}
                    type='number'
                    onBlur={(e) => {
                      let value = parseFloat(e.target.value.replace(',', '.'));
                      if (isNaN(value)) value = 0;
                      if (value === 0) {
                        e.target.value = '';
                      }
                      form.setValue('amount', Number(value.toFixed(2)));
                    }}
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <DialogTitle className='text-2xl'>{t('common.invoiceSelection')}</DialogTitle>
        <FormControl className='bg-neutral-150'>
          <FormCombobox
            name='customerId'
            label={t('common.customer')}
            selectPlaceholder={customerOption?.label ?? t('common.customerChoose')}
            searchPlaceholder='ex: Colas'
            options={searchCustomer ? filteredCustomerOptions : customerOption ? [customerOption] : []}
            searchTerm={searchCustomer}
            setSearchTerm={setSearchCustomer}
            onSelectionChange={handleCustomerChange}
            maxItems={MAX_ITEMS}
            align='start'
            required
          />
        </FormControl>
        {currentCustomer && (
          <TableProvider>
            <div className='mb-6 grid grid-cols-2 gap-4'>
              <Label className='text-left font-bold'>{t('common.remainingAmount') + ' :'}</Label>
              <Label className='text-right font-bold text-notification'>
                {stringUtils.currencyFormat(unassignedAmount(), currentCurrency)}
              </Label>
            </div>
            <InvoicesToPayTable
              customerId={currentCustomer}
              paymentInvoices={paymentInvoices}
              setPaymentInvoices={handleSetPaymentInvoices}
              totalRemainingAmount={unassignedAmount()}
            />
          </TableProvider>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button type='submit' disabled={!form.formState.isValid || amount <= 0 || unassignedAmount() != 0}>
              {amount > 0
                ? unassignedAmount() > 0
                  ? t('common.amountTooHigh', {
                      amount: stringUtils.currencyFormat(unassignedAmount(), currentCurrency),
                    })
                  : t('common.confirmPaymentOfAmount', { amount: stringUtils.currencyFormat(amount, currentCurrency) })
                : t('common.confirmPayment')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </form>
    </Form>
  );
};

GeneralPayment.displayName = 'GeneralPayment';
export default GeneralPayment;
