import React from 'react';
import { Terminal, AlertCircle, CheckCircle, Bell } from 'lucide-react';
import { AlertUI, AlertTitle, AlertDescription } from './AlertUI';

export type AlertProps = {
  variant?: 'default' | 'destructive' | 'success' | 'notification' | 'warning';
  title?: string;
  description: string;
  className?: string;
};

const Alert: React.FC<AlertProps> = ({ className, variant, title, description }) => {
  const descriptionLineBreak = description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <AlertUI variant={variant} className={className}>
      {variant === 'default' ? <Terminal className='size-4' /> : null}
      {variant === 'destructive' ? <AlertCircle className='size-4' /> : null}
      {variant === 'success' ? <CheckCircle className='size-4' /> : null}
      {variant === 'notification' ? <Bell className='size-4' /> : null}

      {title ? <AlertTitle>{title}</AlertTitle> : null}
      <AlertDescription>{descriptionLineBreak}</AlertDescription>
    </AlertUI>
  );
};

export default Alert;
