import React, { useState } from 'react';
import './input.scss';
import PopupContainer from '../organisms/PopupContainer';
import { IconType } from './Icon';
import { TooltipIcon } from '../molecules/TooltipIcon';
interface InputProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'date' | 'time' | 'datetime-local' | 'month' | 'week';
  size?: 'wide' | 'fit';
  placeholder?: string;
  number?: {
    min: number | null;
    max: number | null;
    step: number | null;
  };
  label?: string;
  hoverDescription?: string;
  toolTipMode?: 'none' | 'info' | 'question' | 'warning';
  prefix?: string;
  suffix?: string;
  onChange?: (newValue: string | number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error: string;
  value: string | number | undefined;
  disabled?: boolean;
  inRow?: boolean;
  autoFocus?: boolean;
  isPopup?: boolean;
  onClosePopup?: (selectedOption: any, linkedData?: any) => void;
  popupConfirmLabel?: string;
  popupConfirmIcon?: IconType;
}

export const Input = ({
  size = 'wide',
  type = 'text',
  toolTipMode = 'none',
  label,
  prefix,
  suffix,
  number = {
    min: null,
    max: null,
    step: null,
  },
  placeholder,
  value,
  error,
  disabled = false,
  inRow = false,
  onChange,
  onBlur,
  ...props
}: InputProps) => {
  const [editing, setEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(typeof value === 'number' ? String(value) : '');

  function getDecimalSeparator(): string {
    const testNumber = 1.1;
    const n = testNumber.toLocaleString().substring(1, 2);
    return n;
  }

  function onBlurNumber(event: React.FocusEvent<HTMLInputElement>) {
    setEditing(false);

    let maybeNumber = parseFloat(currentValue.replaceAll(' ', '').replace(',', '.'));

    // Empty if value is not a number.
    if (isNaN(maybeNumber)) {
      if (typeof onChange === 'function') {
        onChange('');
      }
      return;
    }

    // Apply lower threeshold if min is defined.
    if (typeof number.min === 'number') {
      maybeNumber = Math.max(number.min, maybeNumber);
    }

    // Apply upper threeshold if max is defined.
    if (typeof number.max === 'number') {
      maybeNumber = Math.min(number.max, maybeNumber);
    }

    if (typeof onChange === 'function') {
      onChange(maybeNumber);
    }

    if (onBlur) onBlur(event);
  }

  function onChangeNumber(event: React.ChangeEvent<HTMLInputElement>) {
    let maybeNumber = event.target.value;

    const separator = getDecimalSeparator();
    const notSeparator = separator === ',' ? '.' : ',';

    // Force decimal separator.
    maybeNumber = maybeNumber.replace(notSeparator, separator);

    // Avoid writing minus sign twice.
    maybeNumber = maybeNumber.replace('--', '-');

    // First character could me minus: keep it and remove all other minus signs.
    if (maybeNumber.length > 2) {
      maybeNumber = maybeNumber.charAt(0) + maybeNumber.substring(1).replace('-', '');
    }

    // If min is defined and positive, get rid off all minus signs.
    if (typeof number.min === 'number' && number.min >= 0) {
      maybeNumber = maybeNumber.replace('-', '');
    }

    // Avoid writing decimal separator twice.
    maybeNumber = maybeNumber.replace(`${separator}${separator}`, separator);

    // Avoid duplicated decimal separator character.
    // If there is more then one decimal separator, keep only the first two parts.
    maybeNumber = maybeNumber.split(separator).slice(0, 2).join(separator);

    // Remove all characters except minus, decimal separator and numbers.
    maybeNumber = maybeNumber.replace(new RegExp(`[^-${separator}\\d]`), '');

    setCurrentValue(maybeNumber);
  }

  function onFocusNumber() {
    if (typeof value === 'number') {
      setCurrentValue(value.toLocaleString());
    } else {
      setCurrentValue('');
    }

    setEditing(true);
  }

  const content = (
    <div
      className={['dune-content-input', `dune-content-input-${size}`].join(' ')}
      title={toolTipMode !== 'none' ? '' : props.hoverDescription}
    >
      {label ? (
        <>
          <div className={['dune-input-box', inRow ? 'dune-input-box-in-row' : ''].join(' ')}>
            <span className='dune-label'>{label}</span>
            {toolTipMode !== 'none' && <TooltipIcon icon={toolTipMode} message={props.hoverDescription ?? ''} />}
          </div>
        </>
      ) : (
        ''
      )}
      {prefix || suffix ? (
        <div className={['dune-input-box', inRow ? 'dune-input-box-in-row' : ''].join(' ')}>
          {prefix && (
            <span className={['dune-input-prefix', inRow ? 'dune-input-prefix-in-row' : ''].join(' ')}>{prefix}</span>
          )}
          <input
            type={type === 'number' ? 'text' : type}
            max={number.max ?? undefined}
            min={number.min ?? undefined}
            step={number.step ?? undefined}
            placeholder={placeholder ?? undefined}
            value={
              type === 'number'
                ? editing
                  ? currentValue
                  : typeof value === 'undefined'
                    ? ''
                    : value.toLocaleString()
                : value ?? undefined
            }
            onChange={
              type === 'number'
                ? onChangeNumber
                : (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event?.target.value)
            }
            onBlur={type === 'number' ? onBlurNumber : onBlur}
            onFocus={type === 'number' ? onFocusNumber : undefined}
            autoFocus={props.autoFocus || props.isPopup}
            disabled={disabled}
            className={[
              'dune-input',
              prefix || suffix ? (prefix ? 'dune-input-with-prefix' : 'dune-input-with-suffix') : '',
              inRow ? 'dune-input-in-row' : '',
              disabled ? 'dune-input-disabled' : '',
            ].join(' ')}
          />
          {suffix && (
            <span className={['dune-input-suffix', inRow ? 'dune-input-suffix-in-row' : ''].join(' ')}>{suffix}</span>
          )}
        </div>
      ) : (
        <input
          type={type === 'number' ? 'text' : type}
          max={number.max ?? undefined}
          min={number.min ?? undefined}
          step={number.step ?? undefined}
          placeholder={placeholder ?? undefined}
          value={
            type === 'number'
              ? editing
                ? currentValue
                : typeof value === 'undefined'
                  ? ''
                  : value.toLocaleString()
              : value ?? undefined
          }
          onChange={
            type === 'number'
              ? onChangeNumber
              : (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event?.target.value)
          }
          onBlur={type === 'number' ? onBlurNumber : onBlur}
          onFocus={type === 'number' ? onFocusNumber : undefined}
          autoFocus={props.autoFocus || props.isPopup}
          disabled={disabled}
          className='dune-input'
        />
      )}
      {error ? <span className='dune-input-error'>{error}</span> : ''}
    </div>
  );

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          onConfirm={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(value);
          }}
          onCancel={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(null);
          }}
          confirmLabel={props.popupConfirmLabel}
          confirmIcon={props.popupConfirmIcon}
        >
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
};
