import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { SelectCustomer, SelectCustomerOption } from '../../forms/SelectCustomer';
import { Input } from '../../../stories/dune/atoms/Input';
import { OrderSave, GetOrder, OrderProduct } from '../../../models/Order';
import moment from 'moment';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/useToast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import { SelectBuildingSite, SelectBuildingSiteOption } from '../../forms/SelectBuildingSite';
import { ProductItems } from '../../forms/ProductItems';
import { ErrorLine } from '../../../stories/dune/atoms/ErrorLine';
import BuildingSite from '../../../models/BuildingSite';
import Customer from '../../../models/Customer';
import { ParagraphInput } from '../../../stories/dune/atoms/ParagraphInput';
import { ProductItemRow, productItemsActions } from '../../../store/productItems-slice';
import { useDispatch } from 'react-redux';
import store from '../../../store';
import { duplicationActions } from '../../../store/duplication-slice';
import { SelectCarrier, SelectCarrierOption } from '../../forms/SelectCarrier';
import { t } from 'i18next';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { fetchCustomerDetails } from '../../../services/customer';
import { handleApiError } from '../../../utils/apiErrorHandler';

interface GeneralOrderProps {
  id?: string;
  orderData: GetOrder | undefined;
  orderCustomer: Customer | undefined;
  orderBuildingSite: BuildingSite | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;
  onReload?: () => void;

  mode: string;

  dataParent?: string;
}

const GeneralOrder = React.memo((props: GeneralOrderProps) => {
  const history = useNavigate();
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();

  const dispatch = useDispatch();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [saveOrder, setSaveOrder] = useState<OrderSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [customerId, setCustomerId] = useState('');
  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(
    props.orderData?.customer
      ? {
          value: props.orderData.customerId,
          label: props.orderData.customer.label || '', // Update the label to be a string or an empty string if undefined
          data: props.orderData.customer,
        }
      : null,
  );
  const [buildingSiteId, setBuildingSiteId] = useState<string>();
  const [buildingSiteOption, setBuildingSiteOption] = useState<SelectBuildingSiteOption | null>(null);
  const [customerOrderNumber, setCustomerOrderNumber] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();

  const [dateStart, setDateStart] = useState<Date>(new Date());
  const [dateEnd, setDateEnd] = useState<Date>();

  const [orderComment, setOrderComment] = useState<string>();

  const [carrierOption, setCarrierOption] = useState<SelectCarrierOption | null>(null);

  const [orderDataCustom, setOrderDataCutom] = useState<DataCustomItem>({});

  const [periodError, setPeriodError] = useState<string>();

  interface IFormInputs {
    client: {
      value: string;
      label: string;
    };
    chantier?: {
      value: string;
      label: string;
    };
    externalReference?: string;
    customerOrderNumber?: string;
    dateStart: Date;
    dateEnd: Date | null;
    orderComment?: string;
  }

  const schema = yup.object().shape({
    client: yup.object().shape({
      value: yup.string().required('Le client est obligatoire'),
      label: yup.string().required('Le client est obligatoire'),
    }),
    dateStart: yup.date().default(new Date()).required('La date de début est obligatoire'),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.orderCustomer) {
      updateCustomer(props.orderCustomer);
    }
    if (props.orderBuildingSite) {
      updateBuildingSite(props.orderBuildingSite);
    }

    if (props.orderData) {
      // on initialise SaveOrder pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveOrder({
        ...props.orderData,
        carriers: props.orderData.carriers?.map((c) => c.id ?? '') ?? [],
        products: props.orderData.products?.map((p) => p.OrderProduct) ?? [],
      });
      setIsActive(props.orderData?.isActive ?? true);

      if (props.orderData.customer) {
        setCustomerId(props.orderData.customerId);
        setValue('client', {
          value: props.orderData.customer.id ?? '',
          label: props.orderData.customer.label ?? '',
        });
        setCustomerOption({
          value: props.orderData.customer.id ?? '',
          label: props.orderData.customer.label ?? '',
          data: props.orderData.customer as Customer,
        });
      }
      if (props.orderData.buildingSite) {
        setBuildingSiteId(props.orderData.buildingSite.id);
        setBuildingSiteOption({
          value: props.orderData.buildingSite.id ?? '',
          label: props.orderData.buildingSite.label ?? '',
          data: props.orderData.buildingSite as BuildingSite,
        });
      }

      setExternalReference(props.orderData?.externalReference);
      setCustomerOrderNumber(props.orderData?.customerOrderNumber);

      setDateStart(props.orderData?.dateStart ?? new Date());
      setValue('dateStart', props.orderData?.dateStart ?? new Date());
      setDateEnd(props.orderData?.dateEnd ?? undefined);

      if (props.orderData.carriers && props.orderData.carriers.length > 0) {
        setCarrierOption({
          value: props.orderData.carriers[0].id ?? '',
          label: props.orderData.carriers[0].label ?? '',
          data: props.orderData.carriers[0],
        });
      }

      setOrderComment(props.orderData?.orderComment);

      setOrderDataCutom(props.orderData?.dataCustom as any);

      const products: ProductItemRow[] = [];

      props.orderData.products?.map((x: any) => {
        products.push({
          id: x.OrderProduct.id,
          productId: x.id,
          label: x.label,
          desc: x.externalReference,
          externalReference: x.OrderProduct.externalReference,
          comment: x.OrderProduct.comment,
          unit: x.unit,
          weightByUnit: x.weightByUnit,
          orderedQuantity: x.OrderProduct.orderedQuantity,
          deliveredQuantity: x.OrderProduct.deliveredQuantity,
          baseUnitPrice: x.OrderProduct.genericPrice, // en modif, on garde le genericPrice stocké lors de la création
          itemUnitPrice: x.OrderProduct.quotedPrice,
          discountPercent: x.OrderProduct.discount,
          vatRate: x.OrderProduct.vatRate,
          vatRateId: x.OrderProduct.vatRateId,
          repValue: x.OrderProduct.repValue ?? 0,
          tgapValue: x.OrderProduct.tgapValue,
          mainProduct: false,
          enableTransportPrice:
            x.OrderProduct.orderProductTransportPrices && x.OrderProduct.orderProductTransportPrices.length > 0,
          showTransportDetails: false,
        });
      });

      dispatch(productItemsActions.setProducts(products));
    }
  }, [props.orderData, props.orderCustomer, props.orderBuildingSite]);

  useEffect(() => {
    const fetchCurrentCustomerDetails = async (id?: string) => {
      if (!token || !orgid || !id) {
        return;
      }

      try {
        const fetchedCustomerDetails = await fetchCustomerDetails({ accessToken: token, orgid, customerId: id });

        const { id: fetchedCustomerId, label } = fetchedCustomerDetails;
        setValue('client', {
          value: fetchedCustomerId,
          label: label || '',
        });

        setCustomerOption({
          value: fetchedCustomerId,
          label: label || '',
          data: fetchedCustomerDetails,
        });
      } catch (error) {
        const isError = axios.isAxiosError(error) && error.response;
        isError ? handleApiError({ error, addToast, history }) : undefined;
      }
    };

    fetchCurrentCustomerDetails(props.dataParent);
  }, [props.dataParent]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/order/add';
    if (props.mode == 'update') baseUrl = orgid + '/order/edit/' + props.id;

    // #region validation

    let bOk = true;

    // date coherence
    if (dateEnd !== undefined && moment(dateStart) > moment(dateEnd)) {
      bOk = false;
      setPeriodError(t('common.endDateBeforeStartDate'));
    }

    // #endregion validation

    if (bOk) {
      const dataToSend: OrderSave = { ...saveOrder };

      dataToSend.externalReference = externalReference;
      dataToSend.customerOrderNumber = customerOrderNumber;
      dataToSend.dateStart = dateStart;
      dataToSend.dateEnd = dateEnd;
      dataToSend.orderComment = orderComment;
      dataToSend.buildingSiteId = buildingSiteId;
      dataToSend.customerId = customerId;
      dataToSend.isActive = isActive;

      const sentProducts: OrderProduct[] = [];

      const productItems = store.getState().productItems;

      productItems.products?.map((product: ProductItemRow) => {
        sentProducts.push({
          id: product.id,
          productId: product.productId,
          orderedQuantity: product.orderedQuantity ?? 0,
          deliveredQuantity: product.deliveredQuantity ?? 0,
          orderProductComment: product.comment,
          mainProduct: false,
        });
      });
      dataToSend.products = sentProducts;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsAddingLoading(false);
          addToast(t('common.order' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  function updateBuildingSite(newValue: BuildingSite) {
    setBuildingSiteId(newValue?.id ?? '');
    if (newValue && customerId !== newValue.customerId && newValue.customer) {
      setCustomerId(newValue?.customerId ?? '');
      setCustomerOption({
        value: newValue?.customerId ?? '',
        label: newValue?.customer?.label ?? '',
        data: newValue?.customer,
      });
      setSaveOrder((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.id, customerId: newValue?.customerId ?? '' };
      });
    } else {
      setSaveOrder((prevValue) => {
        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.id };
      });
    }
    setBuildingSiteOption({
      value: newValue?.id ?? '',
      label: newValue?.label ?? '',
      data: newValue,
    });
    setValue('chantier', {
      value: newValue?.id ?? '',
      label: newValue?.label ?? '',
    });
  }
  function updateCustomer(newValue: Customer) {
    setCustomerId(newValue?.id ?? '');
    setSaveOrder((prevValue) => {
      if (prevValue) return { ...prevValue, customerId: newValue?.id ?? '' };
    });
    setCustomerOption({
      value: newValue.id ?? '',
      label: newValue.label ?? '',
      data: newValue,
    });
    setValue('client', {
      value: newValue.id ?? '',
      label: newValue.label ?? '',
    });
  }

  function handleDuplicateOrder() {
    if (props.orderData) {
      const dataToDuplicate = props.orderData;
      dispatch(duplicationActions.addOrderToDuplicate(dataToDuplicate));
      history('/orders/add/' + '');
    }
  }

  return !props.orderData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          {props.mode === 'add' && (
            <FormRow>
              {!props.orderCustomer && (
                <SelectCustomer
                  register={register}
                  registerName='client'
                  setValue={setValue}
                  error={errors.client?.value?.message ?? errors.client?.label?.message ?? ''}
                  isSelectable={true}
                  selectedOptionChanged={(newValue) => {
                    setCustomerId(newValue?.value);
                    setSaveOrder((prevValue) => {
                      if (prevValue) return { ...prevValue, customerId: newValue?.value };
                    });
                  }}
                  forceSelectedOption={customerOption}
                />
              )}

              {!props.orderBuildingSite && (
                <SelectBuildingSite
                  titleOverride={t('common.jobSite')}
                  hoverDescription={t('common.buildingSite')}
                  register={register}
                  registerName='chantier'
                  setValue={setValue}
                  error={errors.chantier?.value?.message ?? errors.chantier?.label?.message ?? ''}
                  isSelectable={true}
                  selectedOptionChanged={(newValue) => {
                    setBuildingSiteId(newValue?.value);
                    if (newValue?.data && customerId !== newValue.data.customerId && newValue.data.customer) {
                      setCustomerId(newValue.data.customerId);
                      setCustomerOption({
                        value: newValue.data.customerId,
                        label: newValue.data.customer.label,
                        data: newValue.data.customer,
                      });
                      setSaveOrder((prevValue) => {
                        if (prevValue)
                          return { ...prevValue, buildingSiteId: newValue.value, customerId: newValue.customerId };
                      });
                    } else {
                      setSaveOrder((prevValue) => {
                        if (prevValue) return { ...prevValue, buildingSiteId: newValue?.value };
                      });
                    }
                  }}
                  searchParent={customerId}
                  forceSelectedOption={buildingSiteOption}
                />
              )}
            </FormRow>
          )}
          <FormRow alignVert='center'>
            <Input
              {...register('customerOrderNumber')}
              error={errors.customerOrderNumber?.message ?? ''}
              label={t('common.customerOrderNumber')}
              type='text'
              value={customerOrderNumber ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('customerOrderNumber', e.toString());
                setCustomerOrderNumber(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
            {props.mode === 'update' && (
              <>
                <h3 className='base2'>{t('common.active')}</h3>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </>
            )}
          </FormRow>
          <FormRow>
            <Title label={t('common.products')} type='title1' />
          </FormRow>
          <ProductItems
            label={t('common.products')}
            canAdd={true}
            canRemove={true}
            isLoading={false}
            showTransportMode={false}
            canEditTransportMode={false}
            showOrderedQuantity={true}
            showDeliveredQuantity={true}
            canEditOrderedQuantity={true}
            showUnitPrice={false}
            showProductPrice={false}
            showDiscountValue={false}
            canEditDiscountValue={false}
            showRowExVatPrice={false}
            showRowInclVatPrice={false}
            showTotal={false}
            showTotalDetails={false}
            showProductComment={true}
          />
        </div>
        <div className='form-section' title={t('common.deliveryPeriodDesc')}>
          <Title label={t('common.deliveryPeriod')} type='title1' />
          <FormRow>
            <div className='panelInput'>
              <Input
                error={errors.dateStart?.message ?? ''}
                label={t('common.start')}
                type='date'
                {...register('dateStart')}
                value={moment(dateStart)?.toISOString()?.substring(0, 10)}
                placeholder='DD/MM/YY HH:mm'
                onChange={(newValue: string | number) => {
                  setDateStart(newValue ? new Date(newValue as string) : new Date());
                  setValue('dateStart', newValue ? new Date(newValue as string) : new Date());
                }}
              />
            </div>
            <div className='panelInput'>
              <Input
                error={errors.dateEnd?.message ?? ''}
                label={t('common.end')}
                type='date'
                {...register('dateEnd')}
                value={dateEnd !== undefined ? moment(dateEnd)?.toISOString()?.substring(0, 10) : undefined}
                placeholder='DD/MM/YY HH:mm'
                onChange={(newValue: string | number) => setDateEnd(new Date(newValue as string))}
              />
            </div>
          </FormRow>
          {periodError && <ErrorLine label={periodError} />}
        </div>
        <div className='form-section'>
          <FormRow>
            <SelectCarrier
              register={register}
              registerName='carriers'
              setValue={setValue}
              error={''}
              isSelectable={true}
              selectedOptionChanged={(newValue) => {
                setSaveOrder((prevValue) => {
                  return { ...prevValue, carriers: newValue?.value ? [newValue?.value] : undefined };
                });
              }}
              forceSelectedOption={carrierOption}
            />
          </FormRow>
          <FormRow>
            <ParagraphInput
              onChange={(newValue: string) => setOrderComment(newValue)}
              error={errors.orderComment?.message ?? ''}
              value={orderComment ?? ''}
              label={t('common.comment')}
            />
          </FormRow>
        </div>
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Order'}
            dataCustom={orderDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveOrder((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
          {props.mode === 'update' && (
            <Button iconLeft='add' label={t('common.duplicate')} style='primary-02' onClick={handleDuplicateOrder} />
          )}
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralOrder.displayName = 'GeneralOrder';
export default GeneralOrder;
