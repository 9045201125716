import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { useTabFilters } from '../../../hooks/useTabFilters';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import { GetZone, GetZonePayload, GetZoneParams } from '../../../models/Zone';
import Switch from 'react-switch';
import useToast from '../../../hooks/useToast';
import { ToastTypes } from '../../../models/ToastTypes';
import { isActiveList } from '../../../models';
import EnumTranslate from '../../../utils/EnumTranslate';
import { usePagination } from '../../../hooks/usePagination';
import useAuthGuard from '../../../hooks/useAuthGuard';
import { t } from 'i18next';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { fetchZones } from '../../../services/zone';

interface ListZonesProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListZones = React.memo((props: ListZonesProps) => {
  const { addToast } = useToast();
  const { token, orgid } = useAuthGuard();
  const { currentPage, perPage, paginationSetValues, changePaginationTabValue } = usePagination();
  const defaultTab = props.defaultStatus ?? 'Tous';
  const { filtersTab } = useTabFilters(defaultTab);
  const history = useNavigate();

  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('label');
  const [sortDirection, setSortDirection] = useState('asc');

  const [filteredItems, setFilteredItems] = useState<GetZone[]>([]);
  const [isActive, setIsActive] = useState<isActiveList>({});

  const fetchItems = async (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    tabFilters: string[] = filtersTab,
  ) => {
    try {
      if (!token || !orgid) {
        return;
      }

      setIsLoading(true);
      setFilteredItems([]);

      if (newSortColumn != sortColumn) {
        setSortColumn(newSortColumn);
      }

      if (newSortDirection != sortDirection) {
        setSortDirection(newSortDirection);
      }

      const payload = generatePayload(tabFilters);

      const params: GetZoneParams = {
        limit: newPerPage,
        page,
        sortBy: newSortColumn,
        orderBy: newSortDirection,
      };

      const { items, total } = await fetchZones({
        accessToken: token,
        orgid,
        payload,
        params,
      });

      setTotalRows(total);
      setFilteredItems(items);

      const activeList = items.reduce<isActiveList>((acc, zone) => {
        acc[zone.id] = zone.isActive;
        return acc;
      }, {});
      setIsActive(activeList);
    } catch (error) {
      const isError = axios.isAxiosError(error) && error.response;
      isError ? handleApiError({ error, addToast, history }) : undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const generatePayload = (tabFilters: string[]) => {
    const payload: GetZonePayload = {};

    if (tabFilters?.length > 0) payload.isActive = tabFilters[0] === 'Actif';

    return payload;
  };

  const handlePageChange = (pageNumber: number) => {
    paginationSetValues(pageNumber, perPage);
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    paginationSetValues(1, newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortZone: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortZone = 'desc';
      } else {
        newSortZone = 'asc';
      }
    } else {
      newSortZone = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortZone);
  };

  function handleSetFiltersAdvanced() {
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab);
  }

  const seeDetails = (row: GetZone) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'zones';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.label'),
      selector: (row: GetZone) => row.label,
      cell: (row: GetZone) => (
        <Link to={seeDetails(row)}>
          <div className='firstColumn' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.label}
            </strong>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'label',
    },
    {
      name: t('common.occupation'),
      selector: (row: GetZone) =>
        row.currentOccupation + '/' + ((row.maxOccupation ?? null) !== null ? row.maxOccupation : '∞'),
      cell: (row: GetZone) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.currentOccupation + '/' + ((row.maxOccupation ?? null) !== null ? row.maxOccupation : '∞')}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'currentOccupation',
    },
    {
      name: t('common.associatedProducts'),
      selector: (row: GetZone) => row.productCounter,
      cell: (row: GetZone) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.productCounter}{' '}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'productCounter',
    },

    {
      name: t('common.type'),
      selector: (row: GetZone) => EnumTranslate.GetZoneTypeTranslated(row.zoneType ?? ''),
      cell: (row: GetZone) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {EnumTranslate.GetZoneTypeTranslated(row.zoneType ?? '')}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'zoneType',
    },
    {
      name: t('common.active'),
      selector: (row: GetZone) => row.isActive,
      cell: (row: GetZone) => (
        <Switch onChange={onChangeSwitch} checked={isActive[row.id] ?? false} id={row.id} onColor={'#2a85ff'} />
      ),
      sortable: false,
      right: false,
      id: 'isActive',
    },
  ];

  const onChangeSwitch = (checked: boolean, event: any, id: string) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/zone/change-activation/' + id;

    axios
      .get(Config.getApiExtranetUrl(url), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        addToast(t('common.zoneUpdated'), ToastTypes.success);
        setIsActive((prevState) => ({ ...prevState, [id]: checked }));
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function handleSetFiltersStatus(newFilters: string[], tabOption: string) {
    const { page: newPage, perPage: newPerPage } = changePaginationTabValue(tabOption);
    fetchItems(newPage, newPerPage, sortColumn, sortDirection, newFilters);
  }

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={false}
        allowAdvancedSearch={false}
        advancedSearchOptions={[]}
        showAddNew={props.canAddNew}
        addNewText={t('common.newZone')}
        addNew={() => {
          history('./add');
        }}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={[]}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />

      <TabFilter
        tabArray={[
          { tabFilter: 'Tous', tabLabelTranslate: t('common.all') },
          { tabFilter: 'Actif', tabLabelTranslate: t('common.active') },
        ]}
        filterSet={handleSetFiltersStatus}
        defaultTab={defaultTab}
      />

      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noZone')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListZones.displayName = 'ListZones';
export default ListZones;
