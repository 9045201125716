import Product from './Product';
import DefaultModel from './DefaultModel';
import { VatRate } from './VatRate';
import { Invoice } from './Invoice';
import Customer from './Customer';
import { roundNumber } from '../utils/number';
import Zone from './Zone';
import AdditionalDoc from './AdditionalDoc';
import BuildingSite from './BuildingSite';
import TripType from './TripType';
import { Carrier } from './Carrier';
import Order from './Order';
import Site from './Site';
import { Tracking } from './Tracking';
import Vehicle from './Vehicle';

export enum TripStatus {
  ANOMALY = 'Anomaly',
  ASKING_FOR_EXIT = 'AskingForExit',
  CAN_ENTER = 'CanEnter',
  CAN_EXIT = 'CanExit',
  CANCELED = 'Canceled',
  CREATED = 'Created',
  DELETED = 'Deleted',
  EXITED = 'Exited',
  GO_TO_RECEPTION = 'GoToReception',
  ON_SITE = 'OnSite',
  PLANNED = 'Planned',
  PROCESSED = 'Processed',
  PROCESSING = 'Processing',
  REFUSED = 'Refused',
  STARTED = 'Started',
  WAITING_FOR_APP_PROCESSING = 'WaitingForAppProcessing',
  WAITING_FOR_DRIVER_ENTRY = 'WaitingForDriverEntry',
  WAITING_FOR_DRIVER_EXIT = 'WaitingForDriverExit',
  WAITING_FOR_EQUIPMENT_ENTRY = 'WaitingForEquipmentEntry',
  WAITING_FOR_EQUIPMENT_EXIT = 'WaitingForEquipementExit',
  WAITING_FOR_EQUIPMENT_PROCESSING = 'WaitingForEquipmentProcessing',
  WAITING_FOR_ERP_ENTRY = 'WaitingForERPEntry',
  WAITING_FOR_ERP_EXIT = 'WaitingForErpExit',
  WAITING_FOR_ERP_PROCESSING = 'WaitingForERPProcessing',
  WAITING_FOR_MANAGER_ENTRY = 'WaitingForManagerEntry',
  WAITING_FOR_MANAGER_EXIT = 'WaitingForManagerExit',
  WAITING_FOR_MANAGER_PROCESSING = 'WaitingForManagerProcessing',
  WAITING_FOR_SPACE_ENTRY = 'WaitingForSpaceEntry',
  WAITING_FOR_SPACE_EXIT = 'WaitingForSpaceExit',
  WAITING_FOR_SPACE_PROCESSING = 'WaitingForSpaceProcessing',
}
export enum TripPriority {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
}

export enum TripFilters {
  IN_PROGRESS = TripStatus.CREATED +
    ';' +
    TripStatus.PLANNED +
    ';' +
    TripStatus.STARTED +
    ';' +
    TripStatus.WAITING_FOR_SPACE_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_EQUIPMENT_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_MANAGER_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_ERP_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_DRIVER_ENTRY +
    ';' +
    TripStatus.ON_SITE +
    ';' +
    TripStatus.WAITING_FOR_SPACE_PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_EQUIPMENT_PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_ERP_PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_APP_PROCESSING +
    ';' +
    TripStatus.PROCESSED +
    ';' +
    TripStatus.PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_SPACE_EXIT +
    ';' +
    TripStatus.WAITING_FOR_EQUIPMENT_EXIT +
    ';' +
    TripStatus.WAITING_FOR_ERP_EXIT +
    ';' +
    TripStatus.WAITING_FOR_DRIVER_EXIT +
    ';' +
    TripStatus.WAITING_FOR_MANAGER_EXIT +
    ';' +
    TripStatus.CAN_EXIT +
    ';' +
    TripStatus.ASKING_FOR_EXIT +
    ';' +
    TripStatus.REFUSED +
    ';' +
    TripStatus.ANOMALY +
    ';' +
    TripStatus.CAN_ENTER +
    ';' +
    TripStatus.GO_TO_RECEPTION,
  WAITING = TripStatus.WAITING_FOR_SPACE_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_MANAGER_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_ERP_ENTRY +
    ';' +
    TripStatus.WAITING_FOR_ERP_PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_SPACE_PROCESSING +
    ';' +
    TripStatus.WAITING_FOR_SPACE_EXIT +
    ';' +
    TripStatus.WAITING_FOR_ERP_EXIT +
    ';' +
    TripStatus.WAITING_FOR_MANAGER_EXIT +
    ';' +
    TripStatus.ASKING_FOR_EXIT,
  FINISHED = TripStatus.EXITED + ';' + TripStatus.CANCELED + ';' + TripStatus.DELETED,
  CANCELED = TripStatus.CANCELED + ';' + TripStatus.DELETED,
  EXITED = TripStatus.EXITED,
}

export interface GetTripParameters {
  status?: TripStatus[];
  processingZone?: string;
  currentZone?: string;
  tripType?: string[];
  startDate?: string;
  endDate?: string;
  registrationPlate?: string;
  customerOrderNumber?: string;
  tripNumber?: string;
  tripExternalReference?: string;
  customerId?: string;
  buildingSiteId?: string;
  orderId?: string;
  carrierId?: string;
}

export default class Trip extends DefaultModel {
  id?: string;
  externalReference?: string;
  tripNumber?: string;
  status?: TripStatus;
  trailerPlate?: string;
  registrationPlateRead?: string;
  tripDate?: Date;
  maxWeightOut?: number;
  weightIn?: number;
  dsdIn?: string;
  weightOut?: number;
  dsdOut?: string;
  tripComment?: string;
  tripDeliveryComment?: string;
  customerOrderNumber?: string;
  dataCustom?: string;
  sendToSxd?: boolean;
  tripPriority?: TripPriority;
  mustReturnBeforeLeaving?: boolean;
  waitBeforeExit?: boolean;
  hasBeenRefused?: boolean;
  sxdId?: string;
  sentToSxdAt?: Date;
  tripTypeId?: string;
  tripType?: TripType;
  siteId?: string;
  site?: Site;
  vehicleId?: string;
  vehicle?: Vehicle;
  processingZoneId?: string;
  processingZone?: Zone;
  orderId?: string;
  order?: Order;
  buildingSiteId?: string;
  buildingSite?: BuildingSite;
  customerId?: string;
  customer?: Customer;
  carrierId?: string;
  carrier?: Carrier;
  trackingId?: string;
  tracking?: Tracking;
  additionalDocs?: AdditionalDoc[];
  products?: TripTripProduct[];
  processingZones?: Zone[];
}

export interface TripTripProduct extends Product {
  TripProduct: TripProduct;
}

export interface TripProduct {
  id?: string;
  tripId?: string;
  productId?: string;
  externalReference?: string;
  comment?: string;
  mainProduct?: boolean;
  orderedQuantity?: number;
  deliveredQuantity?: number;
  deliveryDate?: Date;
  vatRate?: VatRate;
  repValue?: number;
  tgapValue?: number;
  unitPrice?: number;
  invoiceId?: string;
  invoice?: Invoice;
}

export const getTotalTripProductPrice = (tripProduct: TripProduct) => {
  const cVal =
    (tripProduct?.deliveredQuantity ?? 0) *
    (1 + (tripProduct?.vatRate?.vatRate ?? 0)) *
    ((tripProduct?.repValue ?? 0) + (tripProduct?.tgapValue ?? 0) + (tripProduct?.unitPrice ?? 0));
  return cVal;
};

export const getTotalTripPrice = (TripTripProducts: TripTripProduct[]) => {
  return roundNumber(
    TripTripProducts.reduce((a, b) => a + getTotalTripProductPrice(b.TripProduct), 0),
    2,
  );
};
