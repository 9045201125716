import { configureStore } from '@reduxjs/toolkit';
import ProductItemsReducer from './productItems-slice';
import DuplicationReducer from './duplication-slice';
import StaticDataReducer from './staticData-slice';

const store = configureStore({
  reducer: {
    productItems: ProductItemsReducer,
    duplication: DuplicationReducer,
    staticData: StaticDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.onClick'], // Ignore the onClick function in the Toast model
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
