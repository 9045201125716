import { AxiosError } from 'axios';
import { t } from 'i18next';
import { NavigateOptions, To } from 'react-router-dom';
import { ToastTypes } from '../models/ToastTypes';

const ERROR_CODES = ['ERR4010001', 'ERR4031001', 'ERR4010000'];

export type ApiErrorMessages = {
  [key: string]: string;
};

const generateToastMessage = (error: AxiosError): string => {
  if (error.response?.data?.code) {
    const errorCode = error.response.data.code;
    return `${errorCode}: ${t('errors.' + errorCode)}`;
  }

  const status = error?.response?.status;
  return `${t('common.genericErrorMessage')}${status ? ` (${status})` : ''}`;
};

export const handleApiError = ({
  error,
  addToast,
  history,
}: {
  error: AxiosError;
  addToast: (message: string, type: ToastTypes) => void;
  history: (to: To, options?: NavigateOptions) => void;
}): void => {
  if (ERROR_CODES?.includes(error.response?.data.code)) {
    history('/');
  }
  addToast(generateToastMessage(error), ToastTypes.error);
};


export type ErrorData = {
  code: string;
  httpStatus: number;
  detail: string;
  title: string;
};
export const decodeApiError = (error: AxiosError, errorMessages: ApiErrorMessages): string => {
  if (!error.response?.data) {
    return errorMessages['default'];
  }

  const errorData = error.response?.data as ErrorData;
  const message = errorMessages[errorData.code];
  return message || errorMessages['default'];
};

export const generateDefaultErrorMessages = (): ApiErrorMessages => ({
  ERR4000000: t('errorMessage.400'),
  ERR4010002: t('errorMessage.401'),
  ERR4040000: t('errorMessage.404'),
  ERR4009999: t('errorMessage.4009'),
  default: t('errorMessage.default'),
});
