import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

import LoginLayout from '../layout/LoginLayout';
import { useQuery } from '../../hooks/useQuery';

const ResetPassword: React.FC = () => {
  const { queryParams } = useQuery();
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const showResetPasswordForm = token;
  return (
    <LoginLayout>
      {showResetPasswordForm ? (
        <ResetPasswordForm username={email ?? ''} resetToken={token} />
      ) : (
        <ForgotPasswordForm formEmail={email} />
      )}
    </LoginLayout>
  );
};

export default ResetPassword;
