import { PaymentSave } from '../models/Payment';
import { createInstance } from '../Config';


const instance = createInstance({ isExtranet: true });

export const createPayment = async ({
  accessToken,
  orgid,
  payload,
}: {
  accessToken: string;
  orgid: string;
  payload: PaymentSave;
}): Promise<{ uuid: string }> => {
  const response = await instance.post(
    `${orgid}/payment/add`,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return response.data.content;
};
