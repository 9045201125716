import dayjs from 'dayjs';
import { Weekday } from '../types';

export const HOME_URL = '/';
export const REDIRECT_COUNTDOWN_IN_SECOND = 3;
export const FETCH_TABLE_ROW_LIMIT_INFITE_SCROLL = 100;
export const FETCH_TABLE_ROW_LIMIT_VIRTUALIZED = 1000;
export const NA = '-';
export const COOKIES_TO_DELETE = ['token', 'currentWorkspace', 'operation', 'userDetail'];
export const LOCAL_STORAGE_KEYS_TO_DELETE = ['currentUserPermissions'];
export const DEFAULT_MIN_RANGE_TIME = '5';
export const DEFAULT_MAX_RANGE_TIME = '24';

export const WEEKDAYS: Weekday[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const DEFAULT_MONTH = dayjs().month() + 1;
export const DEFAULT_YEAR = dayjs().year();
export const FIRST_DAY_OF_YEAR = dayjs().startOf('year').format('YYYY-MM-DD');
export const LAST_DAY_OF_YEAR = dayjs().endOf('year').format('YYYY-MM-DD');
export const YESTERDAY = dayjs().add(-1, 'day').format('YYYY-MM-DD');
export const TODAY = dayjs().format('YYYY-MM-DD');
export const TOMORROW = dayjs().add(1, 'day').format('YYYY-MM-DD');

export const UNCLICKABLE_CELLS = ['select', 'tabletId'];
